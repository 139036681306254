import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'ideals-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonComponent {
  @Input() width = '100%';
  @Input() height = '16px';
  @Input() styleClass: string;

  public containerStyle(): { width: string; height: string } {
    return { width: this.width, height: this.height };
  }
}
