import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {LOCATION} from '@ideals/types';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    clarity: any;
  }
}

interface IClarityConfig {
  readonly enabled: boolean;
  readonly key: string;
}

function getClarityConfig(href: string): IClarityConfig {
  if (href.includes('qa.stagevdr.com')) {
    return {
      enabled: true,
      key: 'jxa0zcg6mb'
    };
  }

  if (href.includes('idealsvdr.com')) {
    return {
      enabled: true,
      key: 'k9tgx7w65h'
    };
  }

  return {
    enabled: false,
    key: ''
  };
}

@Injectable({providedIn: 'root'})
export class ClarityService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(LOCATION) private readonly _location: Location
  ) {
  }

  public initialize(): void {
    const {enabled, key} = getClarityConfig(this._location.href);

    if (enabled) {
      this._createClarityScript(key);
    }
  }

  private _createClarityScript(key: string): void {
    const script = this._document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.clarity.ms/tag/${key}`;
    this._document.body.appendChild(script);

    window.clarity = window.clarity || ((...args: Array<unknown>): void => {
      (window.clarity.q = window.clarity.q || []).push(args);
    });
  }
}
