import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Expressions} from '@ideals/utils';
import {IPasswordComplexity} from '../interfaces';

export class CustomValidators {
  public static whitespaceValidator(control: AbstractControl): ValidationErrors {
    const onlyWhitespace = (control.value || '').trim().length === 0;

    return onlyWhitespace ? {required: true} : undefined;
  }

  public static digitsAndLatinOnly(control: AbstractControl): ValidationErrors {
    return Expressions.digitsAndLetters(control.value) ? undefined : {digitsAndLatinOnly: true};
  }

  public static specCharValidator(control: AbstractControl): ValidationErrors {
    return Expressions.specChar(control.value) ? undefined : {specChar: true};
  }

  public static allCharsValid(): ValidationErrors {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const pwd = control.value;

      if (!pwd) {
        return undefined;
      }

      const allCharsInvalid = /[^A-Za-z0-9~`!@#$%^&*()_+-={}[\]\\|:";'<>?,./]+/.test(pwd);

      return allCharsInvalid ? {allCharsInvalid} : undefined;
    };
  }

  public static uppercaseLetter(uppercaseLetterTemp: number): ValidationErrors {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const pwd = control.value;

      if (!pwd) {
        return undefined;
      }

      const uppercaseLetter = new RegExp(`^(?=.*[A-Z]{${uppercaseLetterTemp}})`).test(pwd);

      return !uppercaseLetter ? {uppercaseLetter} : undefined;
    };
  }

  public static lowercaseLetter(lowercaseLetterTemp: number): ValidationErrors {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const pwd = control.value;

      if (!pwd) {
        return undefined;
      }

      const lowercaseLetter = new RegExp(`^(?=.*[a-z]{${lowercaseLetterTemp}})`).test(pwd);

      return !lowercaseLetter ? {lowercaseLetter} : undefined;
    };
  }

  public static digitOrSpecChars(digitOrSpecCharsTemp: number): ValidationErrors {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const pwd = control.value;

      if (!pwd) {
        return undefined;
      }

      const digitOrSpecChars = new RegExp(
        `^(?=.*[0-9~\`!@#$%^&*()_+-={}[\\]\\\\|:";'<>?,./]{${
          digitOrSpecCharsTemp
        }})`,
      ).test(pwd);

      return !digitOrSpecChars ? {digitOrSpecChars} : undefined;
    };
  }

  public static confirmPasswordValidator(control: AbstractControl): ValidationErrors {
    if (!control.get) {
      return undefined;
    }

    const pwd = control.get('password').value;
    const confirmPwd = control.get('confirmPwd').value;

    if (pwd && confirmPwd && pwd !== confirmPwd) {
      return {confirmMatch: true};
    }

    return undefined;
  }

  // Note: based on https://gitlab.com/idealscorp/vdr/backend/ideals.shared/-/blob/master/Common/iDeals.Common.Email/Validation/EmailAddressValidator.cs#L16
  // to unify email validation with BE
  public static emailAddressValidator(control: AbstractControl): ValidationErrors {
    const maxLength = 254;
    const atChar = '@';
    const periodChar = '.';
    const emailAddress = control.value ?? '';

    if (emailAddress.length > maxLength) {
      return {email: true};
    }

    if (emailAddress.at(0) === atChar
      || emailAddress.at(-1) === atChar
      || emailAddress.at(0) === periodChar
      || emailAddress.at(-1) === periodChar
    ) {
      return {email: true};
    }

    const atCharIndex = emailAddress.indexOf(atChar);

    if (atCharIndex !== emailAddress.lastIndexOf(atChar)) {
      return {email: true};
    }

    if (atCharIndex < 1
      || emailAddress.at(atCharIndex - 1) === periodChar
      || emailAddress.at(atCharIndex + 1) === periodChar
    ) {
      return {email: true};
    }

    if (emailAddress.indexOf(periodChar, atCharIndex + 1) === -1) {
      return {email: true};
    }

    return undefined;
  }
}

export function passwordValidator(rules: Array<IPasswordComplexity>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const pwd = control.value;
    const errors = {};

    rules.forEach((rule) => {
      let isValid = true;

      switch (rule.type) {
        case 'regex': {
          isValid = new RegExp(rule.params[0] as string).test(pwd);
          break;
        }
        case 'minLength': {
          isValid = rule.params[0] <= pwd.length;
          break;
        }
        case 'maxLength': {
          isValid = rule.params[0] >= pwd.length;
          break;
        }
        default: {
          return;
        }
      }

      if (!isValid) {
        errors[rule.message] = true;
      }
    });

    return errors;
  };
}
