// based on https://github.com/ngx-translate/core/issues/223#issuecomment-659418206

/**
 * There are some cases in the app where click on an anchor tag should open modal window. Since this behaviour is different from browser’s default one,
 * we can not just use the replacement mechanism provided by @ngx/translate. The purpose of this directive is to add that mechanism.
 * This is done by using the two directives together.
 * The first one is `idealsTranslatedContent`, where you pass translation key with link to replace. This link must contain an href attribute with placeholder
 * in curly braces and the translated text inside anchor tag that should be wrapped.
 * The second one is `*idealsTranslatedWrapper` structural directive, where you have to pass a placeholder from the href attribute and put the same placeholder
 * inside its content.
 * As a result the anchor tag will be replaced by the element, to which this directive is applied, with the content that is inside of anchor tag in the
 * original translation.
 * Example of usage:
 * in en-US.json:
 * "common.INFO.contact_to_verify_identity": "Contact <a href=\"{{iDealsSupportTeam}}\">Customer Support Team</a> to verify your identity"
 * in template.html:
 * <p idealsTranslatedContent="common.INFO.contact_to_verify_identity">
 *   <ideals-help-button *idealsTranslatedWrapper="'iDealsSupportTeam'">iDealsSupportTeam</ideals-help-button>&nbsp;
 * </p>
 * resulted.html
 * <p idealsTranslatedContent="common.INFO.contact_to_verify_identity">
 *   Contact
 *   <ideals-help-button   *idealsTranslatedElement="'iDealsSupportTeam'">Customer Support Team</ideals-help-button>
 *   to verify your identity
 * </p>
 */

import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, combineLatest, merge, Observable, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {TranslatedWrapperDirective} from './translated-wrapper.directive';

interface ITranslationData {
  elements: Array<TranslatedWrapperDirective>;
  rawTranslation: string;
}

const TOKEN_START_DEMARC = '<a href=';
const TOKEN_END_DEMARC = '</a>';
const TOKEN_CLOSE = '>';
const ELEMENT_KEY_START_DEMARC = '{{';
const ELEMENT_KEY_END_DEMARC = '}}';

@Directive({
  selector: '[idealsTranslatedContent]',
})
export class TranslatedContentDirective implements OnInit, OnDestroy, AfterContentInit {
  @Input('idealsTranslatedContent')
  public translationKey: string;

  @ContentChildren(TranslatedWrapperDirective)
  private readonly _elements: QueryList<TranslatedWrapperDirective>;

  private readonly _subs: Array<Subscription> = [];
  private _rawTranslation: Observable<string>;
  private _translationData: Observable<ITranslationData>;

  constructor(
    private readonly _viewRef: ViewContainerRef,
    private readonly _renderer: Renderer2,
    private readonly _translateService: TranslateService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this._rawTranslation = merge(
      this._translateService.get(this.translationKey),
      this._translateService.onLangChange.asObservable()
        .pipe(switchMap(() => this._translateService.get(this.translationKey)))
    );
  }

  public ngAfterContentInit(): void {
    // QueryList.changes doesn't re-emit after its initial value, which we have by now
    // BehaviorSubjects re-emit their initial value on subscription, so we get what we need by merging
    // the BehaviorSubject and the QueryList.changes observable
    const elementsSubject = new BehaviorSubject(this._elements.toArray());
    const elementsChanges = merge(elementsSubject, this._elements.changes);

    this._translationData = combineLatest([this._rawTranslation, elementsChanges])
      .pipe(
        map(([rawTranslation]) => {
          return {
            elements: this._elements.toArray(),
            rawTranslation,
          };
        })
      );

    this._subs.push(this._translationData.subscribe(this.render.bind(this)));
  }

  private render(translationData: ITranslationData): void {
    if (!translationData.rawTranslation) {
      throw new Error(`No resource matching the key '${this.translationKey}'`);
    }

    while (this._viewRef.element.nativeElement.firstChild) {
      // do not use renderer.removeChild() here because it does not remove all children.
      // possibly this is an angular bug. try to use renderer after upgrading on latest versions
      this._viewRef.element.nativeElement.firstChild.parentNode.removeChild(this._viewRef.element.nativeElement.firstChild);
    }

    let lastTokenEnd = 0;

    while (lastTokenEnd < translationData.rawTranslation.length) {
      const tokenStartDemarc = translationData.rawTranslation.indexOf(TOKEN_START_DEMARC, lastTokenEnd);

      if (tokenStartDemarc < 0) {
        break;
      }

      const translatedTextStart = translationData.rawTranslation.indexOf(TOKEN_CLOSE, tokenStartDemarc) + TOKEN_CLOSE.length;
      const translatedTextEnd = translationData.rawTranslation.indexOf(TOKEN_END_DEMARC, translatedTextStart);
      const elementKeyStart = translationData.rawTranslation.indexOf(ELEMENT_KEY_START_DEMARC, tokenStartDemarc) + ELEMENT_KEY_START_DEMARC.length;
      const elementKeyEnd = translationData.rawTranslation.indexOf(ELEMENT_KEY_END_DEMARC, tokenStartDemarc);

      if (translatedTextEnd < 0) {
        throw new Error(`Encountered unterminated token in translation string '${this.translationKey}'`);
      }

      const translatedText = translationData.rawTranslation.substring(translatedTextStart, translatedTextEnd);
      const tokenEndDemarc = translatedTextEnd + TOKEN_END_DEMARC.length;
      const precedingText = translationData.rawTranslation.substring(lastTokenEnd, tokenStartDemarc);

      const precedingTextElement = this._renderer.createText(precedingText);

      this._renderer.appendChild(this._viewRef.element.nativeElement, precedingTextElement);

      const elementKey = translationData.rawTranslation.substring(elementKeyStart, elementKeyEnd);
      const embeddedElementTemplate = translationData.elements.find((element) => element.elementKey === elementKey);

      if (embeddedElementTemplate) {
        const embeddedElementView = embeddedElementTemplate.viewRef.createEmbeddedView(embeddedElementTemplate.templateRef);
        const rootElement = embeddedElementView.rootNodes[0];

        if (rootElement.children.length === 0) {
          this._renderer.setProperty(rootElement, 'textContent', translatedText);
        } else {
          const allChildren = rootElement.querySelectorAll('*');

          // use for..of instead forEach to support IE
          for (const child of allChildren) {
            if (child.textContent.trim() === elementKey) {
              this._renderer.setProperty(child, 'textContent', translatedText);
            }
          }
        }

        this._renderer.appendChild(this._viewRef.element.nativeElement, rootElement);
      } else {
        const missingTokenText = translationData.rawTranslation.substring(tokenStartDemarc, tokenEndDemarc);
        const missingTokenElement = this._renderer.createText(missingTokenText);

        this._renderer.appendChild(this._viewRef.element.nativeElement, missingTokenElement);
      }

      lastTokenEnd = tokenEndDemarc;
    }

    const trailingText = translationData.rawTranslation.substring(lastTokenEnd);
    const trailingTextElement = this._renderer.createText(trailingText);

    this._renderer.appendChild(this._viewRef.element.nativeElement, trailingTextElement);

    // in case the rendering happens outside of a change detection event, this ensures that any translations in the
    // embedded elements are rendered
    this._changeDetectorRef.detectChanges();

  }

  public ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
  }
}
