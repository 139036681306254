import {inject} from '@angular/core';
import {BrandingService} from '@ideals/services/branding';
import {IRoomLoginInfo} from '@ideals/types';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';

export function roomLoginInfoResolver(): Observable<IRoomLoginInfo> {
  const brandingService = inject(BrandingService);

  return brandingService.getLogotypeWithRoomName().pipe(first());
}
