export enum TermsOfUseUrl {
  'en-US' = 'https://www.idealsvdr.com/terms-of-use/',
  'sv-SE' = 'https://sv.idealsvdr.com/anvandarvillkor-for-ideals-virtuella-datarum/',
  'es-ES' = 'https://es.idealsvdr.com/condiciones-de-uso-de-ideals-virtual-data-room/',
  'it-IT' = 'https://it.idealsvdr.com/condizioni-per-lutilizzo-di-ideals-virtual-data-room/',
  'nl-NL' = 'https://nl.idealsvdr.com/gebruiksvoorwaarden-ideals-virtuele-data-room/',
  'tr-TR' = 'https://tr.idealsvdr.com/ideals-sanal-veri-odasi-kullanim-kosullari/',
  'pl-PL' = 'https://pl.idealsvdr.com/warunki-uzytkowania-aplikacji-ideals-virtual-data-room/',
  'uk-UA' = 'https://ua.idealsvdr.com/%d0%bf%d1%80%d0%b0%d0%b2%d0%b8%d0%bb%d0%b0-%d0%ba%d0%be%d1%80%d0%b8%d1%81%d1%82%d1%83%d0%b2%d0%b0%d0%bd%d0%bd%d1%8f/'
}
