<div class="help-search"
     [class.active]="searchPopup.isPopupOpened"
     [idealsPopup]="searchPopup"
     [popupPlacement]="popupPlacement"
     [popupBoundaries]="popupBoundaries"
     [popupDisableStyle]="true"
     [popupCloseOnToggle]="false"
     data-ideals="helpSearch">
  <div class="help-search__input--wrapper">
    <label class="help-search__input--icon icons-search" for="search-input"></label>
    <input #searchInput
           id="search-input"
           class="help-search__input"
           type="text"
           autocomplete="off"
           maxlength="100"
           [placeholder]="'help_modal.PLACEHOLDER.search_knowledge_base' | translate"
           [(ngModel)]="searchQuery"
           (ngModelChange)="onSearchChange($event)"
           data-ideals="input"/>
    <i class="help-search__input--icon icons-cross"
       *ngIf="searchQuery"
       (click)="onSearchChange(); searchInput.focus()"
       data-ideals="inputClear">
    </i>
  </div>
  <ideals-popup-content #searchPopup class="help-search__dropdown" [hideArrow]="true">
    <div class="help-search__header">
      <span class="help-search__header--no-results" [innerHTML]="searchHeader | translate: searchHeaderParams"></span>
      <p class="help-search__header--empty" *ngIf="emptyData">
        {{'help_modal.MSG.rephrase_query_or_chat' | translate}}
        <a class="open-chat"
           *ngIf="intercomChat"
           idealsShowChat
           data-ideals="openChat">
          <i class="open-chat__icon icons-chat"></i>
          {{'help_modal.BTN.chat_with_us_now' | translate}}
        </a>
      </p>
    </div>
    <div class="help-search__content">
      <ideals-help-article *ngFor="let article of articles; trackBy: trackByIndex"
                           [title]="article.title"
                           [url]="urlWithUTMTags(article.url)">
      </ideals-help-article>
    </div>
    <div class="help-search__footer">
      <a class="help-search__footer--link"
         target="_blank"
         [href]="urlWithUTMTags('help_modal.URL.visit_knowledge_base' | translate, true)"
         data-ideals="visitKnowledgeBase">
        <i class="icon icons-open-link"></i>
        {{'help_modal.LINK.visit_knowledge_base' | translate}}
      </a>
      <a class="help-search__footer--link"
         href="#"
         *ngIf="isDownloadBtnVisible"
         (click)="downloadArticles($event)"
         data-ideals="downloadArticles">
        <i class="icon icons-download"></i>
        {{'help_modal.LINK.download_all_articles' | translate}}
      </a>
    </div>
  </ideals-popup-content>
</div>
