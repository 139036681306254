import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {APP_CONFIG, IAppConfig, WINDOW} from '@ideals/types';
import {TranslateService} from '@ngx-translate/core';
import {HelpLinks} from '../models/classes';

export interface IHelpModalData {
  unauthorizedUser: boolean;
  isDealOwner: boolean;
  isContactsActive: boolean;
  isContactsHidden: boolean;
  isPhoneSupportHidden: boolean;
  close: Function;
}

export enum HelpTabs {
  Contacts,
  HelpCenter
}

@Component({
  selector: 'ideals-help-modal-old',
  templateUrl: './help-modal-old.component.html',
  styleUrls: ['./help-modal-old.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class HelpModalOldComponent {
  public helpLinks: HelpLinks;
  public activeTabIndex: HelpTabs;

  constructor(
    private readonly _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: IHelpModalData,
    @Inject(APP_CONFIG) private readonly _appConfig: IAppConfig,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    this.helpLinks = new HelpLinks(this._appConfig, this._translateService.currentLang);
    this.activeTabIndex = this.data.isContactsActive ? HelpTabs.Contacts : HelpTabs.HelpCenter;
  }

  public get isDealOwner(): boolean {
    return this.data.isDealOwner;
  }

  public get intercomChat(): Function {
    return (this._window as any).Intercom;
  }

  public closeModal(): void {
    this.data.close();
  }
}
