import { inject, Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { NAVIGATOR } from '@ideals/types';

interface CustomWindow extends Window {
  readonly flutter_inappwebview: null;
  readonly webkit: null;
}

@Injectable({ providedIn: 'root' })
export class BrowserService {
  readonly #deviceService = inject(DeviceDetectorService);
  readonly #navigator = inject(NAVIGATOR);

  get deviceInfo(): DeviceInfo {
    return this.#deviceService.getDeviceInfo();
  }

  get isAndroid(): boolean {
    return this.deviceInfo.os === 'Android'
      || this.deviceInfo.device === 'Android';
  }

  get isIOS(): boolean {
    return this.deviceInfo.os === 'iOS'
      || this.deviceInfo.device === 'iPad'
      || this.isIPadOS;
  }

  // iPad Pro/Air detection
  // https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript/58979271#58979271
  get isIPadOS(): boolean {
    const MAX_TOUCH_POINTS = 2;

    return this.#navigator.maxTouchPoints
      && this.#navigator.maxTouchPoints > MAX_TOUCH_POINTS
      && this.#navigator.platform.includes('MacIntel');
  }

  get isMobile(): boolean {
    return this.#deviceService.isMobile()
      || this.isStandalone;
  }

  get isStandalone(): boolean {
    const win = window as CustomWindow & typeof globalThis;

    return win.webkit != null || win.flutter_inappwebview != null;
  }

  isBrowserVersionSupported(version: {
    chrome?: number;
    firefox?: number;
    'ms-edge'?: number;
    'ms-edge-chromium'?: number;
    opera?: number;
    safari?: number;
  }): boolean {
    const supportVersion = version[this.deviceInfo.browser.toLowerCase()] as number;
    const currentBrowserVersion = Number(this.deviceInfo.browser_version.split('.')[0]);

    return supportVersion
      ? supportVersion !== 0 && currentBrowserVersion >= supportVersion
      : false;
  }
}
