import {NgControl} from '@angular/forms';

export abstract class FormFieldControl<T> {
  public value: T | null;
  public readonly placeholder: string;
  public readonly ngControl: NgControl | null;
  public readonly required: boolean;
  public readonly disabled: boolean;
  public readonly errorState: boolean;
  public readonly componentId: string;
}
