import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslatedContentModule} from '@ideals/ui-components/translated-content';
import {TranslateModule} from '@ngx-translate/core';
import {IntercomModule} from '../intercom/intercom.module';
import {AlertComponent} from './alert/alert.component';
import {PageMessagesComponent} from './page-messages.component';

const components = [AlertComponent, PageMessagesComponent];

@NgModule({
  imports: [CommonModule, TranslateModule, TranslatedContentModule, IntercomModule],
  declarations: [...components],
  exports: [...components],
})
export class PageMessagesModule {
}
