export class Expressions {
  private static _whiteCharacters = new RegExp('\\S');
  private static _byCommaOrSemicolon = new RegExp('[;,\\s]', 'g');
  private static _digitsAndLetters = /[^A-Za-z0-9\s]+/;
  private static _specChar = /[\\/:*?"<>|]/;

  public static get byCommaOrSemicolon(): RegExp {
    return Expressions._byCommaOrSemicolon;
  }

  public static haveNotWhiteCharacters(str: string): boolean {
    return Expressions._whiteCharacters.test(str);
  }

  public static digitsAndLetters(str: string): boolean {
    return !Expressions._digitsAndLetters.test(str);
  }

  public static specChar(str: string): boolean {
    return !Expressions._specChar.test(str);
  }
}
