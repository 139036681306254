import {AppPage, IStringMap, ITimeZone} from '@ideals/types';
import {createAction, props} from '@ngrx/store';
import MiniSearch from 'minisearch';
import {IHelpCountry} from '../models/interfaces';
import {Articles, HelpCountries, Videos} from '../models/types';

export const HELP_ACTIONS = {
  SET_ARTICLES: '[HELP] Set Articles',
  SET_FILTERED_ARTICLES: '[HELP] Set Filtered Articles',
  SET_VIDEOS: '[HELP] Set Videos',
  SET_FILTERED_VIDEOS: '[HELP] Set Filtered Videos',
  SET_USER_LOCATION: '[HELP] Set User Location',
  SET_COUNTRIES: '[HELP] Set Countries',
  SET_SELECTED_COUNTRY: '[HELP] Set Selected Country',
  SET_COUNTRY_PHONES_MAPPING: '[HELP] Set Country Phones Mapping',
  SET_SEARCH_INDEX: '[HELP] Set Search Index',
  LOAD_COUNTRIES: '[HELP] Load Countries',
  LOAD_ARTICLES_AND_VIDEOS: '[HELP] Load Articles And Videos',
  SET_APP_PAGE: 'SET_APP_PAGE',
};

export const setArticlesAction = createAction(HELP_ACTIONS.SET_ARTICLES, props<{articles: Articles}>());
export const setFilteredArticlesAction = createAction(HELP_ACTIONS.SET_FILTERED_ARTICLES, props<{filteredArticles: Articles}>());
export const setVideosAction = createAction(HELP_ACTIONS.SET_VIDEOS, props<{videos: Videos}>());
export const setFilteredVideosAction = createAction(HELP_ACTIONS.SET_FILTERED_VIDEOS, props<{filteredVideos: Videos}>());
export const setUserLocationAction = createAction(HELP_ACTIONS.SET_USER_LOCATION, props<{userLocation: ITimeZone}>());
export const setCountriesAction = createAction(HELP_ACTIONS.SET_COUNTRIES, props<{countries: HelpCountries}>());
export const setSelectedCountryAction = createAction(HELP_ACTIONS.SET_SELECTED_COUNTRY, props<{selectedCountry: IHelpCountry}>());
export const setCountryPhonesMappingAction = createAction(HELP_ACTIONS.SET_COUNTRY_PHONES_MAPPING, props<{countryPhonesMapping: IStringMap}>());
export const setSearchIndexAction = createAction(HELP_ACTIONS.SET_SEARCH_INDEX, props<{searchIndex: MiniSearch}>());

export const loadCountriesAction = createAction(HELP_ACTIONS.LOAD_COUNTRIES, props<{loadNew: boolean}>());
export const loadArticlesAndVideosAction = createAction(HELP_ACTIONS.LOAD_ARTICLES_AND_VIDEOS, props<{loadNew: boolean}>());
export const setAppPage = createAction(HELP_ACTIONS.SET_APP_PAGE, props<{ appPage: AppPage }>());
