import {APP_BASE_HREF} from '@angular/common';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BaseHrefInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_BASE_HREF) private readonly _appBaseHref: string) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;

    if (url.startsWith('http') || this._appBaseHref === '/') {
      return next.handle(req);
    }

    return next.handle(req.clone({
      url: url.startsWith('/')
        ? `${this._appBaseHref}${url}`
        : `${this._appBaseHref}/${url}`
    }));
  }
}
