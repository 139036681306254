import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OtpValidationType} from '@ideals/types';
import {Observable} from 'rxjs';
import {ILoginResponse} from '../../types';

@Injectable({providedIn: 'root'})
export class EmailCodeService {
  constructor(private readonly _http: HttpClient) {
  }

  public requestCode(): Observable<void> {
    return this._http.post<void>('api/identity/email-code', {});
  }

  public sendCode(code: string): Observable<ILoginResponse> {
    return this._http.post<ILoginResponse>('api/identity/email-code/verify', {
      code,
      codeVerificationMethod: OtpValidationType.CodeByEmail
    });
  }
}
