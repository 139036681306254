import {Cultures, IAppConfig} from '@ideals/types';

export class HelpLinks {
  public readonly urlAdministratorGuide: string;
  public readonly urlKnowledgeBase: string;
  public readonly urlUserGuide: string;
  public readonly UrlVideoTutorial: string;

  constructor(appConfig: IAppConfig, currentLanguage: string) {
    const defaultUrl = `${appConfig.vdrUrl}/Languages/${currentLanguage}/`;

    this.urlAdministratorGuide = `${defaultUrl}ManualAdmin.pdf`;
    this.urlUserGuide = `${defaultUrl}ManualUser.pdf`;
    this.UrlVideoTutorial = `${appConfig.vdrUrl}/v3/tutorials`;
    this.urlKnowledgeBase = 'http://helpcenter.idealsvdr.com/';

    // eslint-disable-next-line default-case
    switch (currentLanguage) {
      case Cultures.ru_RU:
        this.urlKnowledgeBase += 'ru/';
        break;
      case Cultures.fr_FR:
        this.urlKnowledgeBase += 'fr/';
        break;
      case Cultures.pt_BR:
        this.urlKnowledgeBase += 'pt-BR/';
        break;
      case Cultures.de_DE:
        this.urlKnowledgeBase += 'de/';
        break;
      case Cultures.zh_CN:
        this.urlKnowledgeBase += 'zh-CN/';
        break;
      case Cultures.uk_UA:
        this.urlKnowledgeBase += 'uk/';
        break;
    }
  }
}
