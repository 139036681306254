import {ISelectControlOptions} from '../interfaces';

export class SelectControlOptions implements ISelectControlOptions {
  public forceCheckBoxes = false;
  public closeOnSelect = true;
  public multiple = false;
  public clearable = false;
  public clearOnBackspace = false;
  public searchable = false;
  public appendTo = '';
  public bindValue = 'id';
  public bindLabel = 'caption';
  public groupByTitle = 'caption';
  public notFoundText = '';

  constructor(data?: ISelectControlOptions) {
    Object.assign(this, data);
  }
}
