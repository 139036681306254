import {Cultures} from '@ideals/types';

export const LANGUAGES = [
  {
    id: Cultures.en_US,
    caption: 'English (United States)',
  },
  {
    id: 'zh-CN',
    caption: '中文 (中国)',
  },
  {
    id: 'de-DE',
    caption: 'Deutsch (Deutschland)',
  },
  {
    id: 'sv-SE',
    caption: 'Svenska (Sverige)',
  },
  {
    id: 'fr-FR',
    caption: 'Français (France)',
  },
  {
    id: 'es-ES',
    caption: 'Español (España, Alfabetización Internacional)',
  },
  {
    id: 'pt-BR',
    caption: 'Português (Brasil)',
  },
  {
    id: 'it-IT',
    caption: 'Italiano (Italia)',
  },
  {
    id: 'nl-NL',
    caption: 'Nederlands (Nederland)',
  },
  {
    id: 'ja-JP',
    caption: '日本語 (日本)',
  },
  {
    id: 'ko-KR',
    caption: '한국어 (대한민국)',
  },
  {
    id: 'tr-TR',
    caption: 'Türkçe (Türkiye)',
  },
  {
    id: 'pl-PL',
    caption: 'Polski (Polska)',
  },
  {
    id: 'ru-RU',
    caption: 'Русский (Россия)',
  },
  {
    id: 'uk-UA',
    caption: 'Українська (Україна)',
  },
];
