import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {AppPage, BODY, PersistentObservableComponent, PLACEMENT, WINDOW} from '@ideals/types';
import {filterByFirstParamValue, Utils} from '@ideals/utils';
import {Store} from '@ngrx/store';
import {SearchResult} from 'minisearch';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, first, switchMap, takeUntil} from 'rxjs/operators';
import {HelpAuthDataService} from '../../help-auth-data.service';
import {HelpService} from '../../help.service';
import {HELP_UTM_CAMPAIGN, HELP_UTM_SOURCE} from '../../models/constants';
import {Articles} from '../../models/types';
import {IHelpState} from '../../store/help-state.interface';
import {selectAppPage, selectFilteredArticles} from '../../store/help.getters';

const SEARCH_DEBOUNCE_TIME = 500;

interface ISearchHeaderParams {
  query: string;
  count: number;
}

@Component({
  selector: 'ideals-help-search',
  templateUrl: './help-search.component.html',
  styleUrls: ['./help-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpSearchComponent extends PersistentObservableComponent implements OnInit {
  public readonly popupPlacement = PLACEMENT.BottomStart;
  public readonly popupBoundaries = BODY;

  public searchQuery: string;

  private readonly _searchQueryChange = new Subject<string>();
  private _defaultArticles: Articles;
  private _searchArticles: Array<SearchResult>;
  private _appPage: AppPage;

  constructor(
    private readonly _helpStore: Store<IHelpState>,
    private readonly _helpService: HelpService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _authDataService: HelpAuthDataService,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    super();
  }

  public ngOnInit(): void {
    this._helpStore.select(selectAppPage)
      .pipe(
        filter(filterByFirstParamValue),
        first()
      )
      .subscribe((appPage) => {
        this._appPage = appPage;
      });

    this._helpStore.select(selectFilteredArticles)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data) => {
        this._defaultArticles = data;
      });

    this._searchQueryChange
      .pipe(
        takeUntil(this.componentDestroyed),
        debounceTime(SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        filter((value) => !!value),
        switchMap((query) => this._helpService.getSearchArticles(query)),
      )
      .subscribe((searchArticles) => {
        this._searchArticles = searchArticles;
        this._changeDetectorRef.detectChanges();
      });
  }

  public get isDownloadBtnVisible(): boolean {
    return this._authDataService.isLoggedIn;
  }

  public get searchHeader(): string {
    if (!this._searchArticles) {
      return 'help_modal.TEXT.popular_topics';
    }

    return this.emptyData ? 'help_modal.MSG.no_results' : 'help_modal.MSG.results_count';
  }

  public get searchHeaderParams(): ISearchHeaderParams {
    return {
      query: `<span class="query">${this.searchQuery}</span>`,
      count: this._searchDataLength,
    };
  }

  public get emptyData(): boolean {
    return !!this._searchArticles && this._searchDataLength === 0;
  }

  public get articles(): Articles | Array<SearchResult> {
    return this._searchArticles || this._defaultArticles;
  }

  public get intercomChat(): Function {
    return (this._window as any).Intercom;
  }

  private get _searchDataLength(): number {
    return this._searchArticles && this._searchArticles.length || 0;
  }

  public urlWithUTMTags(url: string, recommended?: boolean): string {
    const params = {
      utm_source: HELP_UTM_SOURCE,
      utm_medium: this._appPage,
      utm_campaign: this.searchQuery && !recommended ? HELP_UTM_CAMPAIGN.SEARCH_RESULT : HELP_UTM_CAMPAIGN.RECOMMENDED,
    };

    return Utils.objectToQueryString(params, url);
  }

  public onSearchChange(value: string = ''): void {
    this.searchQuery = value;

    if (!value) {
      this._searchArticles = undefined;
    }

    this._searchQueryChange.next(value);
  }

  public downloadArticles(event: MouseEvent): void {
    event.preventDefault();

    this._helpService.downloadArticles()
      .subscribe();
  }
}
