import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';

@Injectable()
export class LocalStorageService extends StorageService {
  public get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public delete(key: string): void {
    localStorage.removeItem(key);
  }

  public set(key: string, data: string): void {
    localStorage.setItem(key, data);
  }
}
