import {HttpParams} from '@angular/common/http';

interface IParamsMap {
  [paramName: string]: string;
}

export abstract class Urls {
  private static _paramsList = new RegExp('.*?(?:(?:{)(.*?)(?:}))', 'g');
  private static _lastSlash = new RegExp('\/$', 'g');

  public static getNamedParameters(str: string): Array<string> {
    return str
      .split(Urls._paramsList)
      .filter((param) => param);
  }

  public static getUpdatedNamedParametersStr(str: string, paramsMap: IParamsMap = {}): string {
    const parametersNames = Urls.getNamedParameters(str);
    const url = parametersNames.length
      ? parametersNames.reduce((acc, parameterName) => {
        return acc.replace(`{${parameterName}}`, paramsMap[parameterName] || '');
      }, str)
      : str;

    return url.replace(Urls._lastSlash, '');
  }

  public static getUrlParams(search: string): HttpParams {
    return search
      .slice(search.indexOf('?') + 1)
      .split('&')
      .map((item) => item.split('='))
      .reduce((params, [key, val]) => params.append(key, decodeURIComponent(val)), new HttpParams());
  }

  public static addTrailingSlash(url: string = ''): string {
    return url.replace(/\/?$/, '/');
  }

  public static removeTrailingSlash(url: string = ''): string {
    return url.replace(/\/$/, '');
  }
}
