import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserService} from '@ideals/services/browser';

import {TooltipDirective, TooltipOverflowDirective} from './tooltip.directive';

@NgModule({
  imports: [CommonModule],
  exports: [TooltipDirective, TooltipOverflowDirective],
  declarations: [TooltipDirective, TooltipOverflowDirective],
  providers: [BrowserService],
})
export class TooltipModule {
}
