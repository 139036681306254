import {IPasswordComplexity, ITimeZone, TwoFaMethods} from '@ideals/types';
import {Action, createReducer, on} from '@ngrx/store';
import {IPhone2fa, ProfileInfo} from '../../types';
import * as appActions from '../actions/app.actions';

export interface IAppState {
  profileInfo: ProfileInfo;
  timeZone: ITimeZone;
  passwordRules: Array<IPasswordComplexity>;
  phone: string;
  email: string;
  require2fa: IPhone2fa;
  twoFaMethods: Array<TwoFaMethods>;
}

export const INITIAL_STATE: IAppState = {
  profileInfo: undefined,
  timeZone: undefined,
  passwordRules: undefined,
  phone: undefined,
  email: undefined,
  require2fa: undefined,
  twoFaMethods: undefined
};

export const appReducer = createReducer(
  INITIAL_STATE,
  on(appActions.setProfileInfoAction, (state: IAppState, {profileInfo}) => ({...state, profileInfo})),
  on(appActions.setPasswordComplexityAction, (state: IAppState, {passwordRules}) => ({...state, passwordRules})),
  on(appActions.setTimeZoneAction, (state: IAppState, {timeZone}) => ({...state, timeZone})),
  on(appActions.setPhoneNumberAction, (state: IAppState, {phone}) => ({...state, phone})),
  on(appActions.setEmailAction, (state: IAppState, {email}) => ({...state, email})),
  on(appActions.set2faAction, (state: IAppState, {require2fa}) => ({...state, require2fa})),
  on(appActions.set2FaMethodsAction, (state: IAppState, {twoFaMethods}) => ({...state, twoFaMethods})),
  on(appActions.resetStore, () => ({...INITIAL_STATE}))
);

export function reducer(state: IAppState | undefined, action: Action): IAppState {
  return appReducer(state, action);
}
