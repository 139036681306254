import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ShowChatDirective} from './directives/show-chat.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ShowChatDirective],
  exports: [ShowChatDirective]
})
export class IntercomModule {
}
