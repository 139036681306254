import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LOGIN_PATH} from '../../types';

@Injectable()
export class UserStateErrorsInterceptor implements HttpInterceptor {
  constructor(private readonly _router: Router) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((err) => {
          const {error} = err;
          const state = error?.state;

          if (state) {
            this._router.navigate([`/${LOGIN_PATH}`]);

            return EMPTY;
          }

          return throwError(err);
        })
      );
  }
}
