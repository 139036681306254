import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, IAppConfig} from '@ideals/types';
import {Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DomainValidatorService {
  constructor(@Inject(APP_CONFIG) private readonly _appConfig: IAppConfig) {
  }

  public validate(url: string): Observable<boolean> {
    let givenURL: URL;

    try {
      givenURL = new URL(url);
    } catch {
      return of(false);
    }

    if (!['http:', 'https:'].includes(givenURL.protocol)) {
      return of(false);
    }

    const slice = -2;
    const domain = givenURL
      .hostname
      .replace(/:\d+/, '')
      .split('.')
      .slice(slice)
      .join('.');

    return of(this._appConfig.validDomains.includes(domain));
  }
}
