import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {DomainValidatorService} from '@ideals/services/domain-validator';
import {APP_CONFIG, IAppConfig, PROJECTS_PATH} from '@ideals/types';
import {Utils} from '@ideals/utils';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {RETURN_URL_QUERY_PARAM, STATE_QUERY_PARAM} from '../../types';

const NONCE_SEPARATOR = ';';

@Injectable({providedIn: 'root'})
export class DestinationUrlService {
  constructor(
    @Inject(APP_CONFIG) private readonly _appConfig: IAppConfig,
    private readonly _domainValidator: DomainValidatorService
  ) {
  }

  public extractValidUrlFromRoute(route: ActivatedRouteSnapshot): Observable<string> {
    const projectsUrl = `${this._appConfig.appUrl}/${PROJECTS_PATH}`;
    const url = route.queryParamMap.get(RETURN_URL_QUERY_PARAM);

    if (!url) {
      return of(projectsUrl);
    }

    const params = Utils.queryParamsToObject(url);
    const state = params.get(STATE_QUERY_PARAM);

    if (!state) {
      return of(projectsUrl);
    }

    // VDR3 workaround
    const param = `${RETURN_URL_QUERY_PARAM}=`;
    const index = state.indexOf(param);
    const destinationUrl = index > -1
      ? state.slice(index + param.length)
      // TODO: splitting by $ sign is a VDR3 workaround. remove it on v4
      : state.split(new RegExp(`${NONCE_SEPARATOR}|\\$`))[1];

    if (!destinationUrl) {
      return of(projectsUrl);
    }

    const decodedDestinationUrl = decodeURIComponent(destinationUrl);

    return this._domainValidator
      .validate(decodedDestinationUrl)
      .pipe(map((isValid) => isValid ? decodedDestinationUrl : projectsUrl));
  }

  public appendParamToDestinationUrl(urlWithState: string, paramName: string, paramValue: string): string {
    const url = new URL(urlWithState);
    const state = url.searchParams.get(STATE_QUERY_PARAM);
    const [nonce, destUrl] = state.split(NONCE_SEPARATOR);
    const newDestUrl = new URL(decodeURIComponent(destUrl));
    newDestUrl.searchParams.append(paramName, paramValue);
    // Note: original destination url in state has double encoding. preserve it in new one
    const newState = [nonce, encodeURIComponent(encodeURIComponent(newDestUrl.toString()))].join(NONCE_SEPARATOR);
    // Note: do not use url.searchParams.set(STATE_QUERY_PARAM, newState) because in this case NONCE_SEPARATOR is also encoded, but we need it in raw state
    url.searchParams.delete(STATE_QUERY_PARAM);

    return `${url.toString()}&${STATE_QUERY_PARAM}=${newState}`;
  }
}
