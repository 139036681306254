import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {IVideo} from '../../models/interfaces';

@Component({
  selector: 'ideals-help-video-item',
  templateUrl: './help-video-item.component.html',
  styleUrls: ['./help-video-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HelpVideoItemComponent {
  @Input()
  public video: IVideo;

  @Output()
  public openVideo = new EventEmitter<IVideo>();

  public onVideoClick(event: MouseEvent): void {
    event.preventDefault();

    this.openVideo.emit(this.video);
  }
}
