export enum HTTPStatus {
  NoConnection = 0,
  Ok = 200,
  BadRequest = 400,
  NotLoggedIn = 401,
  AccessForbidden = 403,
  NotAcceptable = 406,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504
}
