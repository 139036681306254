import { Routes } from '@angular/router';

import { loginPagesResolver, roomLoginInfoResolver } from './core';
import {
  EMAIL_CODE_PATH,
  EXTERNAL_IDP_PATH,
  FORGOT_PASSWORD_PATH,
  INCOMPATIBLE_BROWSER_PATH,
  INVITE_PATH,
  LEGAL_AGREEMENTS_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  REGISTRATION_PATH,
  RESET_PASSWORD_INIT_PATH,
  RESET_PASSWORD_PATH,
  RESET_USER_PATH,
  SELECT_ROLE_PATH,
  SIGNIN_ERROR_PATH,
  TWO_FA_PATH,
} from './types';

import { appGuard } from './app.guard';

export const appRoutes: Routes = [
  {
    path: INCOMPATIBLE_BROWSER_PATH,
    loadChildren: () => import('./incompatible-browser/incompatible-browser.module')
      .then((module) => module.IncompatibleBrowserModule),
  },
  {
    path: '',
    canActivate: [appGuard],
    children: [
      {
        path: '',
        redirectTo: LOGIN_PATH,
        pathMatch: 'full',
      },
      {
        path: REGISTRATION_PATH,
        loadChildren: () => import('./registration/local-idp/local-registration.module')
          .then((module) => module.LocalRegistrationModule),
      },
      {
        path: EXTERNAL_IDP_PATH,
        loadChildren: () => import('./registration/external-idp/external-registration.module')
          .then((module) => module.ExternalRegistrationModule),
      },
      {
        path: LOGIN_PATH,
        resolve: { loginPage: loginPagesResolver },
        loadChildren: () => import('./login/login.module')
          .then((module) => module.LoginModule),
      },
      {
        path: TWO_FA_PATH,
        loadChildren: () => import('./2fa/two-fa.module')
          .then((module) => module.TwoFaModule),
      },
      {
        path: SIGNIN_ERROR_PATH,
        loadChildren: () => import('./signin-error/signin-error.module')
          .then((module) => module.SigninErrorModule),
      },
      {
        path: FORGOT_PASSWORD_PATH,
        resolve: { loginPage: loginPagesResolver, roomLoginInfo: roomLoginInfoResolver },
        loadChildren: () => import('./forgot/forgot.module')
          .then((module) => module.ForgotModule),
      },
      {
        path: RESET_PASSWORD_INIT_PATH,
        loadChildren: () => import('./reset-password-init/reset-password-init.module')
          .then((module) => module.ResetPasswordInitModule),
      },
      {
        path: RESET_PASSWORD_PATH,
        loadChildren: () => import('./reset-password/reset-password.module')
          .then((module) => module.ResetPasswordModule),
      },
      {
        path: RESET_USER_PATH,
        loadChildren: () => import('./reset-user/reset-user.module')
          .then((module) => module.ResetUserModule),
      },
      {
        path: INVITE_PATH,
        loadChildren: () => import('./invite/invite.module')
          .then((module) => module.InviteModule),
      },
      {
        path: LEGAL_AGREEMENTS_PATH,
        loadChildren: () => import('./legal-agreements/legal-agreements.module')
          .then((module) => module.LegalAgreementsModule),
      },
      {
        path: LOGOUT_PATH,
        loadChildren: () => import('./logout/logout.module')
          .then((module) => module.LogoutModule),
      },
      {
        path: SELECT_ROLE_PATH,
        loadChildren: () => import('./select-role/select-role.module')
          .then((module) => module.SelectRoleModule),
      },
      {
        path: EMAIL_CODE_PATH,
        loadChildren: () => import('./email-code/email-code.module')
          .then((module) => module.EmailCodeModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: LOGIN_PATH,
  },
];
