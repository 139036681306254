import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {APP_CONFIG, IAppConfig, IBrandingSettings, IRoomLoginInfo, LOCATION, ROOM_NAME_PARAM} from '@ideals/types';
import {Utils} from '@ideals/utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

export const BASE_ROOMS_API_BY_NAME = '/api/ca/branding-settings/room';
export const THEME_COLOR_TO_CLASS = {
  '#ef5350': 'red',
  '#ec407a': 'pink',
  '#ab47bc': 'purple',
  '#9575cd': 'deep-purple',
  '#5c6bc0': 'indigo',
  '#4055b3': 'primary-indigo',
  '#4862d3': 'royal-indigo',
  '#536dfe': 'indigo-modern',
  '#6191ca': 'shark-bay',
  '#358ceb': 'deep-blue',
  '#42a5f5': 'blue',
  '#29b6f6': 'light-blue',
  '#26c6da': 'cyan',
  '#4894b0': 'sea-wave',
  '#26a69a': 'teal',
  '#009688': 'teal-modern',
  '#4caf50': 'hard-green',
  '#42a85f': 'ideals-corp',
  '#66bb6a': 'green',
  '#8bc34a': 'light-green',
  '#f7a738': 'sun',
  '#ff7043': 'deep-orange',
  '#f4640c': 'orange',
  '#e96a48': 'brick',
  '#8d6e63': 'brown',
  '#607d8b': 'blue-grey',
  '#616161': 'grey',
  '#535353': 'matterhorn',
  '#3e3e3e': 'noir',
  '#891157': 'wine',
};
export const DEFAULT_THEME_CLASS = 'ideals-corp';

const RETURN_URL_QUERY_PARAM = 'ReturnUrl';

@Injectable({providedIn: 'root'})
export class BrandingService implements OnDestroy {
  private readonly _onRequestDone = new BehaviorSubject<IBrandingSettings | null>(null);

  constructor(
    private readonly _httpClient: HttpClient,
    @Inject(APP_CONFIG) private readonly _appConfig: IAppConfig,
    @Inject(LOCATION) private readonly _location: Location
  ) {
    this._getBrandingSettings();
  }

  public ngOnDestroy(): void {
    this._onRequestDone.complete();
  }

  public getApplicationBackground(): Observable<string> {
    return this._onRequestDone
      .pipe(
        filter((item) => !!item),
        map((item: IBrandingSettings) => item && item.welcome && item.welcome.location || ''),
        filter((url) => typeof (url) !== 'undefined'),
        distinctUntilChanged()
      );
  }

  public getApplicationThemeClassName(): Observable<string> {
    return this._onRequestDone
      .pipe(
        filter((item) => !!item),
        map((item: IBrandingSettings) => this._getThemeColor(item && item.color && item.color.main)),
        filter((theme) => typeof (theme) !== 'undefined'),
        map((theme) => `theme-${theme}`),
        distinctUntilChanged()
      );
  }

  public getLogotypeWithRoomName(): Observable<IRoomLoginInfo> {
    return this._onRequestDone
      .pipe(
        filter((item) => !!item),
        map((item: IBrandingSettings) => ({
          logotypeUrl: item && item.logoV3 && item.logoV3.location,
          logoHeight: item && item.logoV3 && item.logoV3.height,
          roomTitle: item && item.displayName,
        })),
        distinctUntilChanged()
      );
  }

  private _getRoomName(): string {
    const params = Utils.queryParamsToObject(this._location.search);

    if (params.has(ROOM_NAME_PARAM)) {
      return params.get(ROOM_NAME_PARAM);
    }

    if (params.has(RETURN_URL_QUERY_PARAM)) {
      const returnUrl = params.get(RETURN_URL_QUERY_PARAM);
      const returnUrlParams = Utils.queryParamsToObject(returnUrl);

      if (returnUrlParams.has(ROOM_NAME_PARAM)) {
        return returnUrlParams.get(ROOM_NAME_PARAM);
      }
    }

    return undefined;
  }

  private _getThemeColor(color: string): string {
    const themeClass = THEME_COLOR_TO_CLASS[color];

    return themeClass || DEFAULT_THEME_CLASS;
  }

  private _getBrandingSettings(): void {
    const roomName = this._getRoomName();

    if (roomName) {
      this._httpClient.get<IBrandingSettings>(`${this._appConfig.appUrl}${BASE_ROOMS_API_BY_NAME}`, {
        params: {roomName}
      })
        .subscribe(
          (content) => {
            this._onRequestDone.next(content);
          },
          () => {
            this._onRequestDone.next({} as IBrandingSettings);
          }
        );
    } else {
      this._onRequestDone.next({} as IBrandingSettings);
    }
  }
}
