import {IHelpCountry} from '../interfaces/help-country.interface';

export class HelpCountry implements IHelpCountry {
  public readonly regionName: string;
  public readonly countryName: string;
  public readonly phoneCode: string;
  public readonly countryCode: string;
  public readonly contacts: string;

  constructor(data: IHelpCountry) {
    this.regionName = data.regionName;
    this.countryName = data.countryName;
    this.phoneCode = data.phoneCode;
    this.countryCode = data.countryCode;
    this.contacts = data.contacts;
  }
}
