<div #popupViewRef
     class="ideals-popup"
     role="popup"
     [class.popup]="!popupOptions.disableDefaultStyling"
     [class.hideArrow]="hideArrow"
     [class.innerBorder]="innerBorder"
     [class.round]="isRound"
     [style.display]="displayType"
     [style.opacity]="opacity"
     [style.visibility]="visibility">
  <div class="popup-arrow"></div>
  <div class="popup-inner">
    <ng-content></ng-content>
    {{ text }}
  </div>
</div>
