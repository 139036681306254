import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {IdealsValidators, ValidatorOptions} from '../classes';
import {IValidatorError, IValidatorOptions} from '../interfaces';
import {DecoratedValidatorFunction} from '../types';

const validator = (
  originalMethodValidation: ValidatorFn | DecoratedValidatorFunction,
  defaultFunction: Function,
  propertyKey: string,
  error: IValidatorError
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const result = originalMethodValidation instanceof Function ? originalMethodValidation(control) : defaultFunction(control);

    return result ? {[propertyKey]: error} : undefined;
  };
};

const ValidatorHandler = (options: IValidatorOptions, defaultFunction: Function, propertyKey: string): DecoratedValidatorFunction => {
  return (...args: Array<any>): ValidatorFn => {
    const validatorOption = new ValidatorOptions(options);
    const originalMethodValidation = defaultFunction(...validatorOption.validatorParameters, ...args);
    const error = validatorOption.error || args.pop().error;

    return validator(originalMethodValidation, defaultFunction, propertyKey, error);
  };
};

export function Validator(defaultFunction: Function): PropertyDecorator;
export function Validator(defaultFunction: Function, options?: IValidatorOptions): PropertyDecorator {
  return (target: IdealsValidators, propertyKey: string): void => {
    target[propertyKey] = ValidatorHandler(options, defaultFunction, propertyKey);
  };
}
