import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewContainerRef
} from '@angular/core';
import {BrowserService} from '@ideals/services/browser';
import {PLACEMENT, VIEWPORT, WINDOW} from '@ideals/types';
import {PopupContentOptions, TRIGGERS} from './models/popup';
import {PopupComponent} from './popup.component';

@Directive({
  selector: '[idealsPopup]',
  exportAs: 'idealsPopup'
})
export class PopupDirective implements OnInit, OnChanges, OnDestroy {
  public static baseOptions: PopupContentOptions = new PopupContentOptions({
    disableDefaultStyling: false,
    placement: PLACEMENT.Bottom,
    boundariesElement: VIEWPORT,
    trigger: TRIGGERS.CLICK,
    popupModifiers: {}
  });

  @HostBinding('class.opened')
  public get isOpen(): boolean {
    return this._shown;
  }

  @Input('idealsPopup')
  public content: string | PopupComponent;

  @Input('popupDisabled')
  public disabled: boolean;

  @Input('popupPlacement')
  public placement = PLACEMENT.Bottom;

  @Input('popupBehaviour')
  public behaviour = [PLACEMENT.Bottom, PLACEMENT.Left];

  @Input('popupTrigger')
  public showTrigger = TRIGGERS.CLICK;

  @Input('popupTarget')
  public targetElement: HTMLElement;

  @Input('popupDelay')
  public showDelay = 0;

  @Input('popupTimeout')
  public hideTimeout = 0;

  @Input('popupBoundaries')
  public boundariesElement = 'viewport';

  @Input('popupShowOnStart')
  public showOnStart: boolean;

  @Input('popupHideOnScroll')
  public hideOnScroll: boolean;

  @Input('popupDisableStyle')
  public disableStyle: boolean;

  @Input('popupIE9')
  public popupIE9: boolean;

  @Input('appendToElement')
  public appendToElement: string;

  @Input('popupCloseOnToggle')
  public closeOnToggle = true;

  @Output()
  public popupOnShown = new EventEmitter<PopupDirective>();

  @Output()
  public popupOnHidden = new EventEmitter<PopupDirective>();

  private _popupContentRef: ComponentRef<PopupComponent>;
  private _shown = false;
  private _scheduledShowTimeout: any;
  private _scheduledHideTimeout: any;
  private readonly _subscriptions = [];
  private _eventOptions = {capture: true, passive: true};

  private _hideOnScroll = ($event: MouseEvent): void => {
    this._removeScrollListener();
    this.scheduledHide($event, 0);
  }

  private _hideOnClick = ($event: MouseEvent): void => {
    if (this.disabled || this.showTrigger !== TRIGGERS.CLICK) {
      return;
    }

    this.scheduledHide($event, 0);
  }

  constructor(
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _resolver: ComponentFactoryResolver,
    private readonly _browserService: BrowserService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
  }

  @HostListener('click', ['$event'])
  public showOrHideOnClick($event: MouseEvent): void {
    if (this.disabled || this.showTrigger !== TRIGGERS.CLICK) {
      return;
    }

    this.toggle();
  }

  @HostListener('mousedown', ['$event'])
  public showOrHideOnMouseDown($event: MouseEvent): void {
    if (this.disabled || this.showTrigger !== TRIGGERS.MOUSEDOWN) {
      return;
    }

    this.toggle();
  }

  @HostListener('mouseenter')
  public showOnHover(): void {
    if (this.disabled || this.showTrigger !== TRIGGERS.HOVER) {
      return;
    }
    this.scheduledShow();
  }

  @HostListener('mouseleave', ['$event'])
  public hideOnLeave($event: MouseEvent): void {
    if (this.disabled || this.showTrigger !== TRIGGERS.HOVER) {
      return;
    }
    this.scheduledHide($event, 0);
  }

  public ngOnInit(): void {
    if (typeof this.content === 'string') {
      const text = this.content;
      this.content = this._constructContent();
      this.content.text = text;
    }
    const popupRef = this.content as PopupComponent;
    popupRef.referenceObject = this.getRefElement();
    this._setContentProperties(popupRef);

    if (this.showOnStart) {
      this.scheduledShow();
    }
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (changes['popupDisabled']) {
      if (changes['popupDisabled'].currentValue) {
        this.hide();
      }
    }

    if (changes['placement'] && changes['placement'].currentValue) {
      const popupRef = this.content as PopupComponent;
      popupRef.popupOptions = {
        ...popupRef.popupOptions,
        placement: changes['placement'].currentValue
      };
    }
  }

  public ngOnDestroy(): void {
    // TODO move the subscription and unsubscription to the base class
    this._subscriptions.forEach((sub: any) => sub.unsubscribe && sub.unsubscribe());
    this._subscriptions.length = 0;

    this._removeDocumentClickListener();
    this._removeScrollListener();
  }

  public toggle(): void {
    if (!this._shown) {
      this.scheduledShow();
    } else if (this.closeOnToggle) {
      this.hide();
    }
  }

  public show(): void {
    if (this._shown) {
      this._overrideHideTimeout();

      return;
    }

    this._shown = true;
    const popupRef = this.content as PopupComponent;
    const element = this.getRefElement();
    if (popupRef.referenceObject !== element) {
      popupRef.referenceObject = element;
    }
    popupRef.show();
    this._addDocumentClickListener();
    this._addScrollListener();
    this._changeDetectorRef.detectChanges();
    this.popupOnShown.emit(this);
  }

  public hide(): void {
    if (!this._shown) {
      this._overrideShowTimeout();

      return;
    }

    this._shown = false;
    if (this._popupContentRef) {
      this._popupContentRef.instance.hide();
    } else {
      (this.content as PopupComponent).hide();
    }
    this._removeDocumentClickListener();
    this._removeScrollListener();
    this.popupOnHidden.emit(this);
    this._changeDetectorRef.detectChanges();
  }

  public scheduledShow(delay: number = this.showDelay): void {
    this._overrideHideTimeout();
    this._scheduledShowTimeout = setTimeout(() => {
      this.show();
    }, delay);
  }

  public scheduledHide($event: MouseEvent, delay: number = 0): void {
    this._overrideShowTimeout();
    this._scheduledHideTimeout = setTimeout(() => {
      const toElement = $event.relatedTarget || $event.target;

      if (this.content instanceof PopupComponent) {
        const popupContentView = this.content.popupViewRef
          ? this.content.popupViewRef.nativeElement
          : false;
        if (!popupContentView || popupContentView === toElement || popupContentView.contains(toElement) || this.content.isMouseOver) {
          return;
        }
        this.hide();
      }
    }, delay);
  }

  public getRefElement(): HTMLElement {
    if (this.appendToElement) {
      return document.querySelector(`.${this.appendToElement}`);
    }

    return this.targetElement || this._viewContainerRef.element.nativeElement;
  }

  private _overrideShowTimeout(): void {
    if (this._scheduledShowTimeout) {
      clearTimeout(this._scheduledShowTimeout);
      this._scheduledHideTimeout = 0;
    }
  }

  private _overrideHideTimeout(): void {
    if (this._scheduledHideTimeout) {
      clearTimeout(this._scheduledHideTimeout);
      this._scheduledHideTimeout = 0;
    }
  }

  private _constructContent(): PopupComponent {
    const factory = this._resolver.resolveComponentFactory(PopupComponent);
    this._popupContentRef = this._viewContainerRef.createComponent(factory);

    return this._popupContentRef.instance;
  }

  private _setContentProperties(popupRef: PopupComponent): void {
    popupRef.popupOptions = this._assignDefined(popupRef.popupOptions, PopupDirective.baseOptions, {
      disableDefaultStyling: this.disableStyle,
      placement: this.placement,
      boundariesElement: this.boundariesElement,
      trigger: this.showTrigger,
      popupModifiers: {
        flip: {
          boundariesElement: this.boundariesElement,
          behavior: this.behaviour
        },
        computeStyle: {
          gpuAcceleration: !this.popupIE9
        }
      }
    });

    this._subscriptions.push(popupRef.onHidden.subscribe(this.hide.bind(this)));
  }

  private _addScrollListener(): void {
    if (this.hideOnScroll) {
      this._window.addEventListener('scroll', this._hideOnScroll, this._eventOptions);
    }
  }

  private _removeScrollListener(): void {
    if (this.hideOnScroll) {
      this._window.removeEventListener('scroll', this._hideOnScroll, this._eventOptions);
    }
  }

  private _addDocumentClickListener(): void {
    this._document.addEventListener('click', this._hideOnClick);
  }

  private _removeDocumentClickListener(): void {
    this._document.removeEventListener('click', this._hideOnClick);
  }

  private _assignDefined(target: any, ...sources: Array<any>): any {
    for (const source of sources) {
      for (const key of Object.keys(source)) {
        const val = source[key];
        if (val !== undefined) {
          target[key] = val;
        }
      }
    }

    return target;
  }
}
