import {Action, createReducer, on} from '@ngrx/store';
import {IHelpState} from './help-state.interface';
import {
  loadArticlesAndVideosAction,
  loadCountriesAction,
  setAppPage,
  setArticlesAction,
  setCountriesAction,
  setCountryPhonesMappingAction,
  setFilteredArticlesAction,
  setFilteredVideosAction,
  setSearchIndexAction,
  setSelectedCountryAction,
  setUserLocationAction,
  setVideosAction,
} from './help.actions';

const INITIAL_STATE: IHelpState = {
  articles: undefined,
  articlesLoading: false,
  filteredArticles: undefined,
  videos: undefined,
  videosLoading: false,
  filteredVideos: undefined,
  userLocation: undefined,
  countries: undefined,
  countriesLoading: false,
  selectedCountry: undefined,
  countryPhonesMapping: undefined,
  searchIndex: undefined,
  appPage: undefined,
};

const helpReducer = createReducer(
  INITIAL_STATE,
  on(setArticlesAction, (state: IHelpState, {articles}) => ({...state, articlesLoading: false, articles})),
  on(setFilteredArticlesAction, (state: IHelpState, {filteredArticles}) => ({...state, filteredArticles})),
  on(setVideosAction, (state: IHelpState, {videos}) => ({...state, videosLoading: false, videos})),
  on(setFilteredVideosAction, (state: IHelpState, {filteredVideos}) => ({...state, filteredVideos})),
  on(setUserLocationAction, (state: IHelpState, {userLocation}) => ({...state, userLocation})),
  on(setCountriesAction, (state: IHelpState, {countries}) => ({...state, countriesLoading: false, countries})),
  on(setSelectedCountryAction, (state: IHelpState, {selectedCountry}) => ({...state, selectedCountry})),
  on(setCountryPhonesMappingAction, (state: IHelpState, {countryPhonesMapping}) => ({...state, countryPhonesMapping})),
  on(setSearchIndexAction, (state: IHelpState, {searchIndex}) => ({...state, searchIndex})),
  on(loadArticlesAndVideosAction, (state: IHelpState) => ({...state, articlesLoading: true, videosLoading: true})),
  on(loadCountriesAction, (state: IHelpState) => ({...state, countriesLoading: true})),
  on(setAppPage, (state: IHelpState, {appPage}) => ({...state, appPage})),
);

export function reducer(state: IHelpState | undefined, action: Action): IHelpState {
  return helpReducer(state, action);
}
