import {Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {ControlErrorsScope, InputTypes, Position} from '../enums';
import {IFormControlErrors} from '../interfaces';
import {InputControlConfiguration} from '../types/input-control-configuration.type';
import {PersistentComponent} from './persistent-component.class';
import {SelectControlOptions} from './select-control-options.class';

const MinRowDefault = 4;

@Directive()
export class InputComponentBasicBindings extends PersistentComponent {
  @Input()
  public type: InputTypes = InputTypes.Text;
  @Input()
  public name: string | null = null;
  @Input()
  public digitOnly = false;
  @Input()
  public isRequired = false;
  @Input()
  public isOptional = false;
  @Input()
  public isFocused = false;
  @Input()
  public placeholder = '';
  @Input()
  public label = '';
  @Input()
  public formControl: AbstractControl;
  @Input()
  public formControlName: string | number = '';
  @Input()
  public formArrayName = '';
  @Input()
  public canShowError = false;
  @Input()
  public canShowSuccess = false;
  @Input()
  public processing = false;
  @Input()
  public errors: IFormControlErrors = {};
  @Input()
  public errorsToShow = ControlErrorsScope.FirstOnly;
  @Input()
  public labelPosition: Position = Position.Top;
  @Input()
  public labelCustomClass = '';
  @Input()
  public inputCustomClass = '';
  @Input()
  public prefixClass = '';
  @Input()
  public suffixClass = '';
  @Input()
  public suffixTemplate?: TemplateRef<any>;
  @Input()
  public labelSuffixTemplate?: TemplateRef<any>;
  @Input()
  public readonly: boolean;
  @Input()
  public config: InputControlConfiguration = new SelectControlOptions();
  @Input()
  public minRows = MinRowDefault;
  @Input()
  public parentControlName = '';
  @Input()
  public passwordClassName = '';
  @Input()
  public maxLength = undefined;
  @Input()
  public mask = '';
  @Input()
  public showMaskTyped = false;
  @Input()
  public placeHolderCharacter = '';
  @Input()
  public maskPatterns = undefined;
  @Input()
  public autoComplete: string;
  @Input()
  public errorTransitionEnabled: boolean;
  @Input()
  public arrowFixed: boolean;
  @Input()
  public decimal = true;
  @Input()
  public spellcheck = true;

  @Output()
  public readonly valueChange = new EventEmitter<any>();
  @Output()
  public readonly controlBlur = new EventEmitter<any>();
  @Output()
  public readonly controlFocus = new EventEmitter<any>();
  @Output()
  public readonly keyUpEnter = new EventEmitter<any>();
  @Output()
  public readonly searchForItem = new EventEmitter<any>();
}
