import { Injectable, TemplateRef } from '@angular/core';
import { InformationStatus } from '@ideals/types';
import { Utils } from '@ideals/utils';
import { BehaviorSubject, timer } from 'rxjs';
import { Alerts, IAlert, IMessageConfig } from './models';

export const AUTO_CLOSE_TIMEOUT = 2000;
export const TRANSITION_TIMEOUT = 500;

@Injectable({
  providedIn: 'root'
})
export class PageMessagesService {
  // Note: temporary solution to fix the issue with change detection
  private _messagesSubject = new BehaviorSubject<Alerts>([]);
  private _messages: Alerts = [];

  public get messages(): Alerts {
    return this._messages;
  }

  public messages$ = this._messagesSubject.asObservable();

  public addMessage(
    caption: string,
    type: InformationStatus,
    autoClose: boolean = true,
    closeTimeout: number = AUTO_CLOSE_TIMEOUT,
    preventDuplicates: boolean = true,
    hasIcon: boolean = false,
    htmlCaption?: string,
    htmlCaptionParams?: any,
    customClass?: string,
    template?: TemplateRef<any>
  ): void {
    if (preventDuplicates && this._hasDuplicates(caption)) {
      return;
    }

    const alert = {
      id: Utils.getUniqueId,
      caption,
      type,
      hasIcon,
      isOpened: true,
      htmlCaption,
      htmlCaptionParams,
      customClass,
      template
    };

    if (autoClose) {
      timer(closeTimeout)
        .subscribe(() => this.close(alert));
    }

    this._messages.push(alert);
    this._messagesSubject.next([...this._messages]);
  }

  public close(alert: IAlert): void {
    alert.isOpened = false;
    this._messagesSubject.next([...this._messages]); // Emit new value to trigger change detection

    timer(TRANSITION_TIMEOUT)
      .subscribe(() => {
        const index = this._messages.indexOf(alert);
        this._messages.splice(index, 1);
        this._messagesSubject.next([...this._messages]);
      });
  }

  public success({text, autoClose = true, timeToClose, preventDuplicates, hasIcon, htmlCaption, htmlCaptionParams, customClass}: IMessageConfig): void {
    this.addMessage(text, InformationStatus.Success, autoClose, timeToClose, preventDuplicates, hasIcon, htmlCaption, htmlCaptionParams, customClass);
  }

  public information({
                       text,
                       autoClose = true,
                       timeToClose,
                       preventDuplicates,
                       hasIcon,
                       htmlCaption,
                       htmlCaptionParams,
                       template,
                       customClass
                     }: IMessageConfig): void {
    this.addMessage(
      text,
      InformationStatus.Information,
      autoClose,
      timeToClose,
      preventDuplicates,
      hasIcon,
      htmlCaption,
      htmlCaptionParams,
      customClass,
      template
    );
  }

  public warning({text, autoClose = true, timeToClose, preventDuplicates, hasIcon, htmlCaption, htmlCaptionParams}: IMessageConfig): void {
    this.addMessage(text, InformationStatus.Warning, autoClose, timeToClose, preventDuplicates, hasIcon, htmlCaption, htmlCaptionParams);
  }

  public error({text, autoClose = true, timeToClose, preventDuplicates, hasIcon, htmlCaption, htmlCaptionParams}: IMessageConfig): void {
    this.addMessage(text, InformationStatus.Danger, autoClose, timeToClose, preventDuplicates, hasIcon, htmlCaption, htmlCaptionParams);
  }

  private _hasDuplicates(caption: string): boolean {
    const index = this._messages.findIndex((message) => message.caption === caption);

    return index > -1;
  }
}
