import {Directive, Input} from '@angular/core';
import {Utils} from '@ideals/utils';

@Directive()
export abstract class Persistent {
  @Input()
  public componentId = Utils.getUniqueId;

  public processing = false;

  public static setPrototypeOf(obj: any, proto: any): any {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('MSIE 9') > -1 || userAgent.indexOf('Trident/') > -1) {
      obj.__proto__ = proto;

      return;
    }

    return Object.setPrototypeOf(obj, proto);
  }
}
