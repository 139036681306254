import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '@ideals/types';
import {filterByFirstParamValue} from '@ideals/utils';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {catchError, filter, first, map, switchMap} from 'rxjs/operators';
import {IIntercomSettings, IntercomSettings} from './models';

export const COVIEW = 'coview';
export const COVIEW_URL = 'https://cdn.coview.com/coview.js';
export const COVIEW_PROJECT_KEY = 'Lq-0cRngbM8';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  private readonly _isDoneIntercomLoading = new BehaviorSubject<boolean>(false);
  private _intercomLoaded = false;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _http: HttpClient,
    @Inject(WINDOW) private readonly _window: Window
  ) {
  }

  public get intercomChat(): Function {
    return (this._window as any).Intercom;
  }

  public showChat(): void {
    if (this.intercomChat && this._intercomLoaded) {
      this.intercomChat('show');
    }
  }

  public loadIntercom(): void {
    if (!this._intercomLoaded) {
      this._intercomLoaded = true;

      this._getIntercomSettings()
        .subscribe((intercomSettings) => {
          if (intercomSettings.enabled) {
            const script = this._document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `https://widget.intercom.io/widget/${intercomSettings.app_id}`;
            script.onerror = this._onIntercomError.bind(this);
            script.onload = this._onIntercomLoaded.bind(this);
            this._document.body.appendChild(script);
            this._window['intercomSettings'] = intercomSettings;

            this._loadCoview();
          }
        }, this._onIntercomError.bind(this));
    }
  }

  public updateIntercom(params: any): void {
    this._isDoneIntercomLoading
      .pipe(
        filter(filterByFirstParamValue),
        first()
      )
      .subscribe(() => {
        Object.assign(this._window['intercomSettings'], params);
        this._updateIntercom();
      });
  }

  private _onCoviewError(err: any): void {
    this._window[COVIEW]('error', err);
  }

  private _onCoviewMessage(msg: any): void {
    this._window[COVIEW]('message', msg);
  }

  private _onCoviewLoaded(): void {
    this._window[COVIEW]('start', {
      projectKey: COVIEW_PROJECT_KEY
    });
  }

  private _coview(...args: any): void {
    (this._window[COVIEW].a = this._window[COVIEW].a || []).push(args);
  }

  private _onIntercomLoaded(): void {
    this._isDoneIntercomLoading.next(true);

    const intervalTime = 100;
    const intercomStyles = `<style>
      .intercom-1o4wo1x, .e1tiptuf3 {font-size: 0;}
      .intercom-1o4wo1x:after, .e1tiptuf3:after {content: "30 sec.";font-size: 14px;}
      </style>`;

    this.intercomChat('onShow', () => {
      const interval = setInterval(() => {
        const iframe = document.querySelector('.intercom-messenger-frame iframe') as HTMLIFrameElement;
        const loaded = iframe && iframe.contentDocument && iframe.contentDocument.querySelector('.intercom-messenger');
        if (loaded) {
          iframe.contentDocument.head.insertAdjacentHTML('beforeend', intercomStyles);
          clearInterval(interval);
        }
      }, intervalTime);
    });

    if (this._window['intercomSettings'] && this._window['intercomSettings']['Is authenticated'] === true) {
      const outboundMessageCheckIntervalTime = 1000;
      const millisecondsDivider = 1000;
      const outboundNotificationMessageInterval = setInterval(() => {
        const notificationsFrame = (document.querySelector('iframe[name="intercom-notifications-frame"]')
          || document.querySelector('iframe[name="intercom-borderless-frame"]')
        ) as HTMLIFrameElement;
        const frameDocument = notificationsFrame?.contentDocument;
        const surveyAuthor = frameDocument?.evaluate(
          '//span[text()="Tasha"]',
          frameDocument,
          null,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        );

        if (surveyAuthor?.singleNodeValue) {
          this._window['intercomSettings'].last_survey_date_at = Math.floor(Date.now() / millisecondsDivider);
          this._updateIntercom();
          clearInterval(outboundNotificationMessageInterval);
        }
      }, outboundMessageCheckIntervalTime);
    }
  }

  private _loadCoview(): void {
    this._window[COVIEW] = this._window[COVIEW] || this._coview.bind(this);

    const script = this._document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = COVIEW_URL;
    this._document.body.appendChild(script);
    this._window.addEventListener('error', this._onCoviewError.bind(this));
    this._window.addEventListener('message', this._onCoviewMessage.bind(this));
    script.onload = this._onCoviewLoaded.bind(this);
  }

  private _updateIntercom(): void {
    this.intercomChat('update');
  }

  private _getIntercomSettings(): Observable<IntercomSettings> {
    return this._http.get<IIntercomSettings>('/api/ui-settings/intercom')
      .pipe(
        map((data) => new IntercomSettings(data)),
        catchError(() => EMPTY)
      );
  }

  // TODO: Need add api for log error
  private _onIntercomError(): void {
  }
}
