<a href="#"
   class="help-button-old"
   (click)="openHelpModal($event)"
   data-ideals="showSupportModal">
  <ng-content></ng-content>
  <span #content class="help-button-old__custom">
    <ng-content></ng-content>
  </span>
  <ng-container *ngIf="!content.childNodes.length">
    <i class="icon" [ngClass]="iconClass"></i>
    <span>{{buttonText | translate}}</span>
  </ng-container>
</a>
