import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {USER_EMAIL} from '../../types';

@Injectable({providedIn: 'root'})
export class EmailStorageService {
  constructor(private readonly _cookieService: CookieService) {
  }

  public save(email: string): void {
    const TTL_MINUTES = 30;
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + TTL_MINUTES);
    this._cookieService.set(USER_EMAIL, email, expires, '/');
  }

  public get(): string {
    return this._cookieService.get(USER_EMAIL);
  }

  public remove(): void {
    if (this._cookieService.check(USER_EMAIL)) {
      this._cookieService.delete(USER_EMAIL, '/');
    }
  }
}
