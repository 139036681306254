export * from './cookies';
export * from './elements.constant';
export * from './error-codes';
export * from './keyboard';
export * from './local-storage-items';
export * from './query-param-paths.constant';
export * from './route-paths.constant';
export const ROOM_NAME_PARAM = 'room_name';
export const SKIP_INTERCEPTOR_HEADER = 'X-Skip-Interceptor';
export const SKIP_SPINNER_INTERCEPTOR = 'Skip-Spinner-Interceptor';
