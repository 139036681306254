export const REGISTRATION_PATH = 'registration';
export const EXTERNAL_IDP_PATH = 'external-idp';
export const LOGIN_PATH = 'login';
export const SHARED_FILE_LOGIN_PATH = 'link';
export const SHARED_FILE_UNAVAILABLE_PATH = 'unavailable';
export const PASSWORD_PATH = 'password';
export const CODE_PATH = 'code';
export const LOGOUT_PATH = 'logout';
export const SIGNIN_ERROR_PATH = 'signin-error';
export const TWO_FA_PATH = '2fa';
export const PROFILE_PATH = 'profile';
export const SECURITY_PATH = 'security-settings';
export const ENABLE_2FA_PATH = 'enable-2fa';
export const AUTHENTICATOR_PATH = 'authenticator';
export const SMS_PATH = 'sms';
export const TRY_ANOTHER_WAY_PATH = 'try-another-way';
export const RECOVERY_CODE_PATH = 'recovery-code';
export const RECOVERY_CODES_PATH = 'recovery-codes';
export const FORGOT_PASSWORD_PATH = 'forgot-password';
export const RESET_PASSWORD_PATH = 'reset/password';
export const RESET_USER_PATH = 'user-reset';
export const RESET_PASSWORD_INIT_PATH = 'password-reset';
export const INVITE_PATH = 'invite';
export const LEGAL_AGREEMENTS_PATH = 'legal-agreements';
export const LEGAL_AGREEMENTS_UPDATES_PATH = 'updates';
export const SELECT_ROLE_PATH = 'select-role';
export const EMAIL_CODE_PATH = 'email-code';
export const SEND_US_PATH = 'send-us';
export const INCOMPATIBLE_BROWSER_PATH = 'incompatible-browser';
