import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {PLACEMENT, VIEWPORT} from '@ideals/types';
import Popper, {Boundary} from 'popper.js';
import {PopupContentOptions, TRIGGERS} from './models/popup';

@Component({
  selector: 'ideals-popup-content',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class PopupComponent implements OnDestroy {
  @Input()
  public hideArrow: boolean;

  @Input()
  public escapeWithReference: boolean;

  @Input()
  public innerBorder: boolean;

  @Input()
  public isRound: boolean;

  @ViewChild('popupViewRef', {static: true})
  public popupViewRef: ElementRef<any>;

  public popupOptions = new PopupContentOptions({
    disableDefaultStyling: false,
    placement: PLACEMENT.Bottom,
    boundariesElement: VIEWPORT,
    trigger: TRIGGERS.CLICK,
    popupModifiers: {}
  });
  public isPopupOpened = false;
  public isMouseOver = false;
  public onHidden = new EventEmitter();
  public displayType = 'none';
  public opacity = 0;
  public referenceObject: HTMLElement;
  public text: string;
  public popupInstance: Popper;
  public visibility: string;

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {
  }

  // TODO: rewrite to less loaded method
  @HostListener('document:resize')
  public onDocumentResize(): void {
    this.update();
  }

  public ngOnDestroy(): void {
    if (!this.popupInstance) {
      return;
    }

    this.popupInstance.disableEventListeners();
    this.popupInstance.destroy();
  }

  public show(): void {
    if (!this.referenceObject) {
      return;
    }

    const popupOptions = {
      placement: this.popupOptions.placement,
      modifiers: {
        arrow: {
          element: this.popupViewRef.nativeElement.querySelector('.popup-arrow')
        },
        preventOverflow: {
          boundariesElement: this.popupOptions.boundariesElement as Boundary,
          escapeWithReference: this.escapeWithReference,
        }
      },
      onUpdate: (): void => {
        this.visibility = 'visible';
        this._changeDetectorRef.detectChanges();
      }
    };

    popupOptions.modifiers = {...popupOptions.modifiers, ...this.popupOptions.popupModifiers};

    this.popupInstance = new Popper(this.referenceObject, this.popupViewRef.nativeElement, popupOptions);

    this.popupInstance.enableEventListeners();
    this.toggleVisibility(true);
    this.scheduleUpdate();
  }

  public update(): void {
    if (this.popupInstance) {
      this.popupInstance.update();
    }
  }

  public scheduleUpdate(): void {
    if (this.popupInstance) {
      this.popupInstance.scheduleUpdate();
    }
  }

  public hide(): void {
    if (this.popupInstance) {
      this.popupInstance.destroy();
    }
    this.toggleVisibility(false);
    this.onHidden.emit();
    this._changeDetectorRef.markForCheck();
  }

  public toggleVisibility(state: boolean): void {
    if (!state) {
      this.opacity = 0;
      this.displayType = 'none';
      this.visibility = 'hidden';
      this.isPopupOpened = false;
    } else {
      this.opacity = 1;
      this.displayType = 'block';
      this.isPopupOpened = true;
      // there is issue with initial positioning because of using `display: none` for hiding tooltip
      // see note in docs: https://popper.js.org/docs/v2/modifiers/hide/
      // to avoid this issue do double update(here and in show() method) after display was changed to block
      this.scheduleUpdate();
    }
  }
}
