import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[idealsTranslatedWrapper]',
})
export class TranslatedWrapperDirective {
  @Input('idealsTranslatedWrapper')
  public elementKey: string;

  constructor(
    public readonly viewRef: ViewContainerRef,
    public readonly templateRef: TemplateRef<any>,
  ) {}
}
