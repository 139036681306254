import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {IdealsDialogService} from '@ideals/services/dialog';
import {HelpService} from '../../help.service';
import {HELP_MODAL_DEFAULT_CONFIG} from '../../models/constants';
import {HelpModalComponent} from '../help-modal/help-modal.component';

@Component({
  selector: 'ideals-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HelpButtonComponent {
  constructor(
    private readonly _dialog: IdealsDialogService,
    private readonly _helpService: HelpService,
  ) {
  }

  public openHelpModal(event: MouseEvent): void {
    event.preventDefault();
    this._helpService.initHelpData();

    this._dialog.open(
      HelpModalComponent,
      HELP_MODAL_DEFAULT_CONFIG
    );
  }
}
