import {Directive, InjectFlags, InjectionToken, Injector, OnInit, Type} from '@angular/core';
import {Utils} from '@ideals/utils';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {CULTURE_COOKIE_EXPIRY} from '../constants';
import {Cultures} from '../enums';

@Directive()
export abstract class PersistentRootComponent implements OnInit {
  private static _injector: Injector;
  protected readonly cookieService: CookieService;
  protected readonly translateService: TranslateService;

  protected constructor(injector: Injector, private readonly _isLanguageBrowserBased?: boolean) {
    PersistentRootComponent.injector = injector;
    this.cookieService = PersistentRootComponent.getInstanceOf<CookieService>(CookieService);
    this.translateService = PersistentRootComponent.getInstanceOf<TranslateService>(TranslateService);
    const culture = this._getCulture();
    this.translateService.setDefaultLang(culture);
    this.translateService.use(culture);
  }

  public static set injector(value: Injector) {
    PersistentRootComponent._injector = value;
  }

  public static getInstanceOf<T>(token: Type<T> | InjectionToken<T>, notFoundValue?: T, flags?: InjectFlags): T {
    return PersistentRootComponent._injector.get<T>(token, notFoundValue, flags);
  }

  // possibly this method is called in derived classes, leave it empty to not break them
  public ngOnInit(): void {
  }

  private _getCulture(): string {
    return this.cookieService.get('culture') || this._getDefaultLanguage();
  }

  private _getDefaultLanguage(): string {
    const browserLanguage = this.translateService.getBrowserLang();
    let culture = Cultures.en_US;

    if (browserLanguage) {
      Object.values(Cultures)
        .forEach((lang) => {
          if (lang.includes(browserLanguage)) {
            culture = lang;
          }
        });
    }

    if (this._isLanguageBrowserBased) {
      this.cookieService.set('culture', culture, new Date(CULTURE_COOKIE_EXPIRY), '/', Utils.domain);
    }

    return culture;
  }
}
