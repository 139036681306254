import {Injectable} from '@angular/core';
import {BehaviorSubject, timer} from 'rxjs';
import {debounce, distinctUntilChanged, map} from 'rxjs/operators';

const DEFAULT_AFTER = 800;
const DEFAULT_MINIMUM = 500;

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private _spinnerCounter = new BehaviorSubject<number>(0);

  public spinnerCounter = this._spinnerCounter.asObservable()
    .pipe(
      map((count) => count > 0),
      debounce((isActive) => timer(isActive ? DEFAULT_MINIMUM : DEFAULT_AFTER)),
      distinctUntilChanged()
    );

  public add(): void {
    this._spinnerCounter.next(this._spinnerCounter.value + 1);
  }

  public remove(): void {
    this._spinnerCounter.next(this._spinnerCounter.value > 0 ? this._spinnerCounter.value - 1 : 0);
  }
}
