import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[idealsAutofocus]'
})
export class AutofocusDirective {
  @Input()
  public set idealsAutofocus(value: boolean) {
    this._setFocus(value);
  }

  constructor(private readonly _elementRef: ElementRef) {
  }

  private _setFocus(value: boolean): void {
    if (value !== false) {
      const delay = 300;

      setTimeout(() => {
        this._elementRef.nativeElement.focus();
      }, delay);
    }
  }
}
