import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {AppPage, PersistentComponent} from '@ideals/types';
import {filterByFirstParamValue, Utils} from '@ideals/utils';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {filter, first} from 'rxjs/operators';
import {HELP_UTM_CAMPAIGN, HELP_UTM_SOURCE} from '../../models/constants';
import {IVideo} from '../../models/interfaces';
import {IHelpState} from '../../store/help-state.interface';
import {selectAppPage, selectArticlesLoading, selectFilteredArticles, selectFilteredVideos, selectVideosLoading} from '../../store/help.getters';

export const SUPPORT_EMAIL = 'support@idealsvdr.com';
const ARTICLE_SKELETON_ITEMS_AMOUNT = 5;
const VIDEO_SKELETON_ITEMS_AMOUNT = 2;

@Component({
  selector: 'ideals-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpModalComponent extends PersistentComponent {
  public readonly articleSkeletons = Array(ARTICLE_SKELETON_ITEMS_AMOUNT);
  public readonly videoSkeletons = Array(VIDEO_SKELETON_ITEMS_AMOUNT);
  public readonly supportEmail = SUPPORT_EMAIL;
  public articlesLoading$ = this._helpStore.select(selectArticlesLoading);
  public articles$ = this._helpStore.select(selectFilteredArticles);
  public videosLoading$ = this._helpStore.select(selectVideosLoading);
  public videos$ = this._helpStore.select(selectFilteredVideos);
  public video: IVideo;

  private _appPage: AppPage;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _helpStore: Store<IHelpState>
  ) {
    super();
    this._helpStore.select(selectAppPage)
      .pipe(
        filter(filterByFirstParamValue),
        first()
      )
      .subscribe((appPage) => {
        this._appPage = appPage;
      });
  }

  public get modalTitle(): Observable<string> {
    return this.video ? of(this.video.title) : this._translateService.get('help_modal.HEADER.welcome_to_help_center');
  }

  public openVideo(video: IVideo): void {
    this.video = video;
  }

  public closeVideo(): void {
    this.video = undefined;
  }

  public urlWithUTMTags(url: string): string {
    const params = {
      utm_source: HELP_UTM_SOURCE,
      utm_medium: this._appPage,
      utm_campaign: HELP_UTM_CAMPAIGN.RECOMMENDED,
    };

    return Utils.objectToQueryString(params, url);
  }
}
