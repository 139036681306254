import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {IdealsDialogService} from './dialog.service';

@NgModule({
  imports: [MatDialogModule],
  providers: [IdealsDialogService],
  exports: [MatDialogModule]
})
export class IdealsDialogModule {
}
