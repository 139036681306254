import {IValidatorError} from '../interfaces/validator-error.interface.js';
import {IValidatorOptions} from '../interfaces/validator-options.interface';

export class ValidatorOptions implements IValidatorOptions {
  public validatorParameters: Array<any>;
  public error?: IValidatorError;

  constructor(data: IValidatorOptions) {
    this.validatorParameters = data && data.validatorParameters || [];
    this.error = data && data.error || undefined;
  }
}
