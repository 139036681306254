<div class="help-modal__header"
     [class.video]="video"
     mat-dialog-title
     data-ideals="helpModalHeader">
  <button class="close icons-cross"
          type="button"
          [attr.aria-label]="'Close' | translate"
          [mat-dialog-close]="false"
          data-ideals="dismissModal">
  </button>
  <button class="back icons-left theme-color"
          type="button"
          *ngIf="video"
          (click)="closeVideo()"
          data-ideals="backFromVideo">
  </button>
  <h4 class="modal-title"
      *ngIf="modalTitle | async; let title"
      idealsOverflow
      [ideals-tooltip]="title">
    {{title}}
  </h4>
</div>
<div class="help-modal__container"
     [class.video]="video"
     mat-dialog-content
     data-ideals="helpModalContainer">
  <video class="help-modal__video"
         *ngIf="video else helpCenter"
         controls
         autoplay
         disablePictureInPicture>
    <source *ngFor="let source of video.sources; trackBy: trackByIndex"
            [src]="source.src"
            [type]="source.type">
  </video>
  <ng-template #helpCenter>
    <ideals-help-search></ideals-help-search>
    <div class="help-modal__content">
      <section class="help-modal__section">
        <h3 class="help-modal__section--header">{{'help_modal.TEXT.popular_topics' | translate}}</h3>
        <div class="help-modal__section--content">
          <ng-container *ngIf="(articlesLoading$ | async) === true; else articles">
            <ng-container *ngFor="let skeleton of articleSkeletons">
              <ideals-skeleton styleClass="mb-2" height="24px"></ideals-skeleton>
            </ng-container>
          </ng-container>

          <ng-template #articles>
            <ideals-help-article *ngFor="let article of articles$ | async; trackBy: trackByIndex"
                               [title]="article.title"
                               [url]="urlWithUTMTags(article.url)">
            </ideals-help-article>
          </ng-template>
        </div>
      </section>

      <div *ngIf="videosLoading$ | async; else videos" class="help-modal__section loading">
        <div *ngFor="let skeleton of videoSkeletons" class="video-skeleton">
          <ideals-skeleton class="video-icon" height="58px" width="88px"></ideals-skeleton>
          <ideals-skeleton class="video-title" height="32px"></ideals-skeleton>
        </div>
      </div>

      <ng-template #videos>
        <ng-container *ngIf="videos$ | async as videos">
          <section *ngIf="videos.length" class="help-modal__section">
            <h3 class="help-modal__section--header">{{'help_modal.TEXT.featured_videos' | translate}}</h3>
            <div class="help-modal__section--content">
              <ideals-help-video-item class="help-modal__video-item"
                                      *ngFor="let video of videos; trackBy: trackByIndex"
                                      [video]="video"
                                      (openVideo)="openVideo($event)">
              </ideals-help-video-item>
            </div>
          </section>
        </ng-container>
      </ng-template>
    </div>
    <div class="help-modal__footer">
      <h2 class="help-modal__footer--title">{{'help_modal.TEXT.support_team_24/7' | translate}}</h2>
      <div class="help-modal__footer--buttons">
        <button class="help-modal__footer--button chat theme-bg-color--on-hover" idealsShowChat>
          <i class="icon icons-chat"></i>
          <div class="text">
            <p class="title theme-color--no-hover">{{'help_modal.BTN.chat_with_us' | translate}}</p>
            <p>{{'help_modal.TEXT.response_time_30sec' | translate}}</p>
          </div>
        </button>
        <div class="help-modal__footer--button email">
          <i class="icon icons-letter"></i>
          <div>
            <a class="title theme-color" href="mailto:{{supportEmail}}" idealsOpenMailNewTab>
              {{supportEmail}}
            </a>
            <p>{{'help_modal.TEXT.response_time_30min' | translate}}</p>
          </div>
        </div>
        <ideals-help-contacts class="help-modal__footer--button contacts"></ideals-help-contacts>
      </div>
    </div>
  </ng-template>
</div>
