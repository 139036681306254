import {Cultures, CULTURE_COOKIE_EXPIRY} from '@ideals/types';
import {Utils} from '@ideals/utils';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {first} from 'rxjs/operators';

export function initLangFactory(
  cookieService: CookieService,
  translateService: TranslateService,
  isLanguageBrowserBased: boolean = false
): () => Promise<void> {
  function getDefaultLanguage(): string {
    const browserLanguage = translateService.getBrowserLang();
    let culture = Cultures.en_US;

    if (browserLanguage) {
      Object.values(Cultures)
        .forEach((lang) => {
          if (lang.includes(browserLanguage)) {
            culture = lang;
          }
        });
    }

    if (isLanguageBrowserBased) {
      cookieService.set('culture', culture, new Date(CULTURE_COOKIE_EXPIRY), '/', Utils.domain);
    }

    return culture;
  }

  function getCulture(): string {
    return cookieService.get('culture') || getDefaultLanguage();
  }

  return () => {
    const culture = getCulture();

    translateService.setDefaultLang(culture);
    translateService.use(culture);

    // https://github.com/ngx-translate/core/issues/1086#issuecomment-508779160
    return new Promise((resolve) => {
      translateService.onLangChange
        .pipe(first())
        .subscribe(() => {
          resolve();
        });
    });
  };
}
