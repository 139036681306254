export const HOURS = 24;
export const MINUTES = 60;
export const SECONDS = 60;
export const MILLISECONDS = 1000;
export const ONE_DAY = HOURS * MINUTES * SECONDS * MILLISECONDS;
export const TIMEZONE_OFFSET = SECONDS * MILLISECONDS;

export class DateTime {
  public static get currentYear(): string {
    return (new Date()).getFullYear()
      .toString();
  }

  public static get firstDateOfCurrentMonth(): string {
    const date = new Date();

    date.setDate(1);
    date.setHours(0, 0, 0, 0);

    return DateTime.getTimezoneOffsetDate(date);
  }

  public static get lastDateOfCurrentMonth(): string {
    const today = new Date();
    const date = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    date.setHours(0, 0, 0, 0);

    return DateTime.getTimezoneOffsetDate(date);
  }

  public static get firstDateOfPreviousMonth(): string {
    const date = new Date();

    date.setDate(0);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);

    return DateTime.getTimezoneOffsetDate(date);
  }

  public static get lastDateOfPreviousMonth(): string {
    const date = new Date();

    date.setDate(0);
    date.setHours(0, 0, 0, 0);

    return DateTime.getTimezoneOffsetDate(date);
  }

  public static startOfTheDay(date: string): string {
    const day = new Date(date);

    day.setHours(0, 0, 0, 0);

    return DateTime.getTimezoneOffsetDate(day);
  }

  public static endOfTheDay(date: string): string {
    const day = new Date(date);
    const time = {
      hours: 23,
      minutes: 59,
      seconds: 59,
    };

    day.setHours(time.hours, time.minutes, time.seconds);

    return DateTime.getTimezoneOffsetDate(day);
  }

  public static getTimezoneOffsetDate(date: Date): string {
    return new Date(date.getTime() - (date.getTimezoneOffset() * TIMEZONE_OFFSET)).toISOString();
  }

  public static previousDayDate(date?: string): string {
    const nextDay = date ? new Date(date) : new Date();
    nextDay.setDate(nextDay.getDate() - 1);

    return nextDay.toISOString();
  }

  public static nextDayDate(date?: string): string {
    const nextDay = date ? new Date(date) : new Date();
    nextDay.setDate(nextDay.getDate() + 1);

    return nextDay.toISOString();
  }

  public static stringDatesAreEqual(dateA: string, dateB: string): boolean {
    return new Date(dateA).toLocaleDateString() === new Date(dateB).toLocaleDateString();
  }

  public static compareDates(dateA: string, dateB: string): number {
    const dateTimeA = new Date(dateA).getTime();
    const dateTimeB = new Date(dateB).getTime();

    if (dateTimeA < dateTimeB) {
      return -1;
    }

    if (dateTimeA > dateTimeB) {
      return 1;
    }

    return 0;
  }

  public static compareDatesWithoutTime(dateA: string, dateB: string): number {
    return DateTime.stringDatesAreEqual(dateA, dateB) ? 0 : DateTime.compareDates(dateA, dateB);
  }

  public static dateInRange(date: string, from: string, to: string, withTime?: boolean): boolean {
    if (withTime) {
      return DateTime.compareDates(date, from) >= 0 && DateTime.compareDates(to, date) >= 0;
    }

    return DateTime.compareDatesWithoutTime(date, from) >= 0 && DateTime.compareDatesWithoutTime(to, date) >= 0;
  }

  public static getMinDate(arrDates: Array<string>): string {
    const [min] = arrDates
      .sort(DateTime.compareDates)
      .filter((date) => !!date);

    return min;
  }

  public static getMaxDate(arrDates: Array<string>): string {
    const sorted = arrDates
      .sort(DateTime.compareDates)
      .filter((date) => !!date);

    return sorted[sorted.length - 1];
  }

  public static leftDaysTo(toDate: string, fromDate?: string): number {
    const toDay = (toDate ? new Date(toDate) : new Date())
      .setHours(0, 0, 0, 0);

    const fromDay = (fromDate ? new Date(fromDate) : new Date())
      .setHours(0, 0, 0, 0);

    const invalidDates = isNaN(toDay) || isNaN(fromDay);

    // eslint-disable-next-line no-null/no-null
    return invalidDates ? null : Math.round((toDay - fromDay) / ONE_DAY);
  }

  public static isSameDate(isoDateA: string, isoDateB: string): boolean {
    const dateA = new Date(isoDateA);
    const dateB = new Date(isoDateB);

    return dateA.getFullYear() === dateB.getFullYear()
          && dateA.getMonth() === dateB.getMonth()
          && dateA.getDate() === dateB.getDate();
  }
}
