<a class="help-article theme-color--on-hover"
   target="_blank"
   [href]="url"
   data-ideals="helpArticle">
  <span class="title"
        idealsOverflow
        [ideals-tooltip]="title">
    {{title}}
  </span>
  <i class="icon icons-open-link theme-color"></i>
</a>
