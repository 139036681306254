import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, IAppConfig, ITimeZone} from '@ideals/types';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';

export const RUSSIA_COUNTRY_CODE = 'RU';
export const BELARUS_COUNTRY_CODE = 'BY';
export const BANNED_COUNTRY_CODES = [
  RUSSIA_COUNTRY_CODE,
  BELARUS_COUNTRY_CODE
];

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  private _timeZone$: Observable<ITimeZone>;

  constructor(
    private readonly _http: HttpClient,
    @Inject(APP_CONFIG) private readonly _appConfig: IAppConfig
  ) { }

  public get isBannedLocation(): Observable<boolean> {
    return this.getTimeZone()
      .pipe(
        map(({countryCode}) => BANNED_COUNTRY_CODES.includes(countryCode))
      );
  }

  public getTimeZone(): Observable<ITimeZone> {
    if (!this._timeZone$) {
      this._timeZone$ = this._loadTimeZone();
    }

    return this._timeZone$;
  }

  private _loadTimeZone(): Observable<ITimeZone> {
    return this._http.get<ITimeZone>(`${this._appConfig.ssoUrl}/api/location`)
      .pipe(
        catchError(() => of({} as ITimeZone)),
        shareReplay()
      );
  }
}
