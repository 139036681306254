import {inject} from '@angular/core';
import {setAppPage} from '@ideals/partial-views/help';
import {Store} from '@ngrx/store';

export function loginPagesResolver(): boolean {
  const store = inject(Store);
  store.dispatch(setAppPage({appPage: 'login'}));

  return true;
}
