<button class="close icons-cross"
        type="button"
        [attr.aria-label]="'Close' | translate"
        (click)="close()">
</button>

<div class="message">
  <i class="message__icon"
     *ngIf="alert.hasIcon"
     [style.font-size]="'24px'"
     [ngClass]="iconClass"></i>
  <p class="message__text" *ngIf="alert.caption" [idealsTranslatedContent]="alert.caption">
    <a href="#"
       class="message__link"
       idealsShowChat
       *idealsTranslatedWrapper="'iDealsSupportTeam'">
      iDealsSupportTeam
    </a>
  </p>
  <p *ngIf="alert.htmlCaption" class="message--html" [innerHTML]="alert.htmlCaption | translate: alert.htmlCaptionParams"></p>
</div>
<ng-container *ngTemplateOutlet="alert.template"></ng-container>
