import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {DownloadService} from '@ideals/services/download';
import {FeatureToggleService} from '@ideals/services/feature-toggle';
import {GeoService} from '@ideals/services/geo';
import {APP_CONFIG, Cultures, IAppConfig, IStringMap, ITimeZone, LOCATION} from '@ideals/types';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import MiniSearch, {SearchResult} from 'minisearch';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, filter, first, map, take, tap} from 'rxjs/operators';
import {HelpAuthDataService} from './help-auth-data.service';
import {HelpCountry} from './models/classes';
import {SPACE_OR_PUNCTUATION, SPLIT_HIEROGLYPHS_ONLY} from './models/constants';
import {IHelpCountries} from './models/interfaces';
import {Articles, HelpCountries, SearchArticles, Videos} from './models/types';
import {IHelpState} from './store/help-state.interface';
import {loadArticlesAndVideosAction, loadCountriesAction, setAppPage, setSearchIndexAction} from './store/help.actions';
import {selectAppPage, selectSearchIndex} from './store/help.getters';

type Terms = Array<string>;

enum MatchScore {
  Content = 1,
  Title,
  TitleAndContent
}

const SEARCH_FIELDS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  BODY: 'body',
  URL: 'url',
};

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  private _appUrl = this._appConfig.appUrl;
  private _helpCenterUrl = this._appConfig.helpCenterUrl;
  private _searchIndexSet = false;
  private _dataInitialized = false;

  private static _tokenizePunctuation(term: string): Terms {
    return term.split(SPACE_OR_PUNCTUATION);
  }

  private static _tokenizeBySymbol(term: string): Terms {
    return HelpService._tokenizePunctuation(term)
      .reduce((acc, str) => {
        acc.push(...str.match(SPLIT_HIEROGLYPHS_ONLY));

        return acc;
      }, []);
  }

  constructor(
    private readonly _http: HttpClient,
    private readonly _store: Store<IHelpState>,
    private readonly _translateService: TranslateService,
    private readonly _downloadService: DownloadService,
    private readonly _geoService: GeoService,
    private readonly _authDataService: HelpAuthDataService,
    @Inject(LOCATION) private readonly _location: Location,
    @Inject(APP_CONFIG) private readonly _appConfig: IAppConfig,
    private readonly _featureToggleService: FeatureToggleService
  ) {
  }

  private get _currentLang(): string {
    return this._translateService.currentLang;
  }

  private get _isLangWithHieroglyphs(): boolean {
    return this._currentLang === Cultures.zh_CN;
  }

  public initHelpData(): void {
    if (this._dataInitialized) {
      return;
    }

    if (this._featureToggleService.isEnabled('is-new-help-modal')) {
      this.loadHelpData();
      this._dataInitialized = true;
    }
  }

  public loadHelpData(): void {
    this._setSearchIndex();
    this._store.dispatch(loadCountriesAction({loadNew: false}));

    this._store.select(selectAppPage)
      .pipe(
        tap((appPage) => {
          if (!appPage) {
            this._store.dispatch(setAppPage({appPage: this._appConfig.appPage}));
          }
        }),
        filter((appPage) => !!appPage),
        take(1),
      )
      .subscribe(() => {
        this._store.dispatch(loadArticlesAndVideosAction({loadNew: false}));
      });

    this._translateService.onLangChange
      .subscribe(() => {
        this._setSearchIndex();
        this._store.dispatch(loadCountriesAction({loadNew: true}));
        this._store.dispatch(loadArticlesAndVideosAction({loadNew: true}));
      });
  }

  public getSearchArticles(query: string): Observable<Array<SearchResult>> {
    return this._store.select(selectSearchIndex)
      .pipe(
        first(),
        map((searchIndex) => {
          const results = searchIndex.search(query)
            .sort((a, b) => {
              this._countMatchScore(a);
              this._countMatchScore(b);

              const queryLowerCase = query.toLowerCase()
                .trim();
              const aTitleLowerCase = a.title.toLowerCase();
              const bTitleLowerCase = b.title.toLowerCase();
              const aTitleMatch = aTitleLowerCase.indexOf(queryLowerCase) + 1;
              const bTitleMatch = bTitleLowerCase.indexOf(queryLowerCase) + 1;
              const titleMatch = aTitleMatch && bTitleMatch ? aTitleMatch - bTitleMatch : aTitleMatch ? -1 : bTitleMatch ? 1 : 0;

              return titleMatch || b.matchScore - a.matchScore || b.score - a.score;
            });

          this._logSearchStatistics(query, results.length)
            .subscribe();

          return results;
        }),
      );
  }

  public getLocation(): Observable<ITimeZone> {
    return this._geoService.getTimeZone();
  }

  public getCountryPhonesMapping(): Observable<IStringMap> {
    return this._http.get<IStringMap>(`${this._helpCenterUrl}countries/map-phones.json`)
      .pipe(
        catchError(() => of({})),
      );
  }

  public getHelpCountries(): Observable<HelpCountries> {
    return this._http.get<IHelpCountries>(`${this._helpCenterUrl}countries/${this._currentLang}/content.json`)
      .pipe(
        map(({countries}) => countries.map((country) => new HelpCountry(country))),
        catchError(() => EMPTY),
      );
  }

  public getArticles(): Observable<Articles> {
    return this._http.get<{ content: Articles }>(`${this._helpCenterUrl}content/${this._currentLang}/content.json`)
      .pipe(
        map(({content}) => content),
        catchError(() => EMPTY),
      );
  }

  public getVideos(): Observable<Videos> {
    return this._http.get<{ videos: Videos }>(`${this._helpCenterUrl}videos/videos.json`)
      .pipe(
        map(({videos}) => videos.map(({ sources, ...rest }) => ({
          ...rest,
          sources: sources.map(({src, type}) => ({
            src: `${this._helpCenterUrl}${src}`,
            type,
          }))
        }))),
        catchError(() => EMPTY),
      );
  }

  public downloadArticles(): Observable<Blob> {
    const url = `${this._helpCenterUrl}download/${this._currentLang}/help.html`;
    const fileName = 'iDealsManual.html';

    return this._downloadService.downloadFile(url, fileName);
  }

  private _getSearchIndex(): Observable<SearchArticles> {
    return this._http.get<SearchArticles>(`${this._helpCenterUrl}indexes/${this._currentLang}/index.json`)
      .pipe(
        catchError(() => EMPTY),
      );
  }

  private _logSearchStatistics(searchQueue: string, countSearchResults: number): Observable<undefined> {
    if (!this._authDataService.isLoggedIn || this._authDataService.isIdealsUser) {
      return of();
    }

    const body = {
      searchQueue,
      countSearchResults,
      url: this._location.href
    };

    return this._http.post<undefined>(`${this._appUrl}/api/stats/add`, body)
      .pipe(
        catchError(() => EMPTY),
      );
  }

  private _tokenizeFn(): (term: string) => Terms {
    return this._isLangWithHieroglyphs ? HelpService._tokenizeBySymbol : HelpService._tokenizePunctuation;
  }

  private _setSearchIndex(): void {
    if (this._searchIndexSet) {
      return;
    }

    const searchIndex = new MiniSearch({
      fields: [SEARCH_FIELDS.TITLE, SEARCH_FIELDS.DESCRIPTION, SEARCH_FIELDS.BODY],
      storeFields: [SEARCH_FIELDS.TITLE, SEARCH_FIELDS.URL],
      tokenize: this._tokenizeFn(),
      searchOptions: {
        boost: {
          title: 10,
        },
        prefix: true,
      },
    });

    this._getSearchIndex()
      .subscribe((data) => {
        searchIndex.removeAll();
        searchIndex.addAll(data);
        this._store.dispatch(setSearchIndexAction({searchIndex}));
        this._searchIndexSet = true;
      });
  }

  private _countMatchScore(item: SearchResult): void {
    if (item.matchScore) return;

    const fields = {};
    let matchTitle;
    let matchContent;
    const matchTitleAndContent = item.terms.some((term) => {
      item.match[term].forEach((field) => fields[field] = true);

      matchTitle = fields[SEARCH_FIELDS.TITLE];
      matchContent = fields[SEARCH_FIELDS.DESCRIPTION] || fields[SEARCH_FIELDS.BODY];

      return matchTitle && matchContent;
    });

    item.matchScore = matchTitleAndContent
      ? MatchScore.TitleAndContent
      : matchTitle
        ? MatchScore.Title
        : MatchScore.Content;
  }
}
