import {Directive} from '@angular/core';
import {UntypedFormControl, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {Persistent} from './persistent.class';

export interface IErrorStateMatcherService {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean;
}

export interface ICanUpdateErrorState {
  errorState: boolean;
  errorStateMatcher: IErrorStateMatcherService;

  updateErrorState(): void;
}

export interface IHasErrorState {
  parentFormGroup: FormGroupDirective;
  parentForm: NgForm;
  defaultErrorStateMatcher: IErrorStateMatcherService;
  ngControl: NgControl;
}

@Directive()
export class ErrorStateClass extends Persistent implements ICanUpdateErrorState, IHasErrorState {
  public errorState = false;
  public errorStateMatcher: IErrorStateMatcherService;

  constructor(
    public readonly defaultErrorStateMatcher: IErrorStateMatcherService,
    public readonly parentForm: NgForm,
    public readonly parentFormGroup: FormGroupDirective,
    public readonly ngControl: NgControl
  ) {
    super();
  }

  public updateErrorState(): void {
    const oldState = this.errorState;
    const parent = this.parentFormGroup || this.parentForm;
    const matcher = this.errorStateMatcher || this.defaultErrorStateMatcher;
    const control = this.ngControl ? this.ngControl.control as UntypedFormControl : undefined;
    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
    }
  }
}
