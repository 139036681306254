import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPasswordComplexity, TwoFaMethods} from '@ideals/types';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {IAppState, resetStore, set2FaMethodsAction, setEmailAction, setPhoneNumberAction} from '../../store';

interface IPhone {
  readonly phone: string;
}

interface ITwoFaMethods {
  readonly twoFaMethods: Array<TwoFaMethods>;
}

interface IHasRecoveryCodes {
  readonly activeRecoveryCodesCount: number;
}

export interface IUserMetadata {
  readonly passwordRules?: Array<IPasswordComplexity>;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _store: Store<IAppState>
  ) {
  }

  public getUserMetadata(): Observable<IUserMetadata> {
    return this._http
      .get<IUserMetadata>('api/user/metadata')
      .pipe(map(({passwordRules}) => {
        return {
          passwordRules: passwordRules.filter((rule) => rule.type !== 'required'),
        };
      }));
  }

  public getUserEmail(): Observable<string> {
    return this._http.get<{email: string}>('api/identity/email')
      .pipe(
        map(({email}) => email),
        tap((email) => this._store.dispatch(setEmailAction({email})))
      );
  }

  public getPhoneNumber(): Observable<string> {
    return this._http.get<IPhone>('api/identity/phone')
      .pipe(
        map(({phone}) => phone),
        tap((phone) => this._store.dispatch(setPhoneNumberAction({phone})))
      );
  }

  public getTwoFaMethods(): Observable<Array<TwoFaMethods>> {
    return this._http.get<ITwoFaMethods>('api/identity/2fa-methods')
      .pipe(
        map(({twoFaMethods}) => twoFaMethods),
        tap((twoFaMethods) => this._store.dispatch(set2FaMethodsAction({twoFaMethods})))
      );
  }

  public getActiveRecoveryCodes(): Observable<number> {
    return this._http.get<IHasRecoveryCodes>('api/identity/has-recovery-codes')
      .pipe(map(({activeRecoveryCodesCount}) => activeRecoveryCodesCount));
  }

  public hasRecoveryCodes(): Observable<boolean> {
    return this.getActiveRecoveryCodes()
      .pipe(map((activeRecoveryCodesCount) => activeRecoveryCodesCount > 0));
  }

  public cancelAuthentication(): Observable<void> {
    return this._http.post<void>('api/identity/cancel', {})
      .pipe(
        tap(() => this._store.dispatch(resetStore())),
        catchError(() => of(undefined))
      );
  }
}
