import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {LOCATION} from '@ideals/types';

@Injectable({providedIn: 'root'})
export class DnsPrefetchService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(LOCATION) private readonly _location: Location
  ) {
  }

  public createLinks(): void {
    const currentOrigin = this._location.origin;

    if (currentOrigin.includes('auth.idealsvdr') || currentOrigin.includes('stagevdr')) {
      const appOrigin = currentOrigin.replace('auth', 'app');

      this._createDnsPrefetch(`${appOrigin}/`);
    }
  }

  private _createDnsPrefetch(url: string): void {
    const link = this._document.createElement('link');

    link.rel = 'dns-prefetch';
    link.href = url;
    this._document.head.appendChild(link);
  }
}
