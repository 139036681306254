import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Data, Route, Router} from '@angular/router';
import {FeatureToggleService} from './feature-toggle.service';

function hasAccess(routeData: Data, featureToggleService: FeatureToggleService, router: Router): boolean {
  if (!routeData.featureFlag) {
    throw new Error('Provide feature flag via route data.');
  }

  const hasAccess = featureToggleService.isEnabled(routeData.featureFlag);

  if (hasAccess) {
    return true;
  }

  if (routeData.redirectUrl) {
    router.navigateByUrl(routeData.redirectUrl);
  }

  return false;
}

export function featureToggleGuard(route: Route | ActivatedRouteSnapshot): boolean {
  const featureToggleService = inject(FeatureToggleService);
  const router = inject(Router);
  
  return hasAccess('data' in route ? route.data : route.data, featureToggleService, router);
}
