import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {OpenMailNewTabDirective} from './open-mail-new-tab.directive';

@NgModule({
  declarations: [OpenMailNewTabDirective],
  imports: [CommonModule],
  exports: [OpenMailNewTabDirective]
})
export class OpenMailNewTabModule {
}
