import {AppPage, IStringMap, ITimeZone} from '@ideals/types';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import MiniSearch from 'minisearch';
import {IHelpCountry} from '../models/interfaces';
import {Articles, HelpCountries, Videos} from '../models/types';
import {IHelpState} from './help-state.interface';

export const selectHelpState = createFeatureSelector<IHelpState>('help');

export const selectArticlesLoading = createSelector(selectHelpState, (state): boolean => state.articlesLoading);

export const selectArticles = createSelector(selectHelpState, (state): Articles => state.articles);

export const selectFilteredArticles = createSelector(selectHelpState, (state): Articles => state.filteredArticles);

export const selectVideosLoading = createSelector(selectHelpState, (state): boolean => state.videosLoading);

export const selectVideos = createSelector(selectHelpState, (state): Videos => state.videos);

export const selectFilteredVideos = createSelector(selectHelpState, (state): Videos => state.filteredVideos);

export const selectUserLocation = createSelector(selectHelpState, (state): ITimeZone => state.userLocation);

export const selectCountriesLoading = createSelector(selectHelpState, (state): boolean => state.countriesLoading);

export const selectCountries = createSelector(selectHelpState, (state): HelpCountries => state.countries);

export const selectSelectedCountry = createSelector(selectHelpState, (state): IHelpCountry => state.selectedCountry);

export const selectCountryPhonesMapping = createSelector(selectHelpState, (state): IStringMap => state.countryPhonesMapping);

export const selectSearchIndex = createSelector(selectHelpState, (state): MiniSearch => state.searchIndex);

export const selectAppPage = createSelector(
  selectHelpState,
  (state): AppPage => state && state.appPage
);
