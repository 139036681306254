<div class="ideals-spinner" *ngIf="showSpinner" data-ideals="spinner">
  <svg width="72" height="72" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_468_885)">
      <path d="M47.5 24C47.5 36.9787 36.9787 47.5 24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24Z" stroke="var(--ideals-stone-400)"/>
      <path d="M35.5 24C35.5 33.665 27.665 41.5 18 41.5C8.33502 41.5 0.5 33.665 0.5 24C0.5 14.335 8.33502 6.5 18 6.5C27.665 6.5 35.5 14.335 35.5 24Z" stroke="var(--ideals-stone-400)"/>
      <path class="middle-circle" d="M0.5 24C0.5 33.665 8.33502 41.5 18 41.5C27.665 41.5 35.5 33.665 35.5 24C35.5 14.335 27.665 6.5 18 6.5C8.33502 6.5 0.5 14.335 0.5 24Z" stroke="#2C9C74"/>
      <path d="M19.5 24C19.5 29.2467 15.2467 33.5 10 33.5C4.75329 33.5 0.5 29.2467 0.5 24C0.5 18.7533 4.75329 14.5 10 14.5C15.2467 14.5 19.5 18.7533 19.5 24Z" stroke="var(--ideals-stone-400)"/>
      <path class="inner-circle" d="M0.5 24C0.5 29.2467 4.75329 33.5 10 33.5C15.2467 33.5 19.5 29.2467 19.5 24C19.5 18.7533 15.2467 14.5 10 14.5C4.75329 14.5 0.5 18.7533 0.5 24Z" stroke="#2C9C74"/>
      <path class="outer-circle" d="M0.5 24C0.5 36.9787 11.0213 47.5 24 47.5C36.9787 47.5 47.5 36.9787 47.5 24C47.5 11.0213 36.9787 0.5 24 0.5C11.0213 0.5 0.5 11.0213 0.5 24Z" stroke="#2C9C74"/>
    </g>
    <defs>
      <clipPath id="clip0_468_885">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</div>
