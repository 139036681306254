<a class="help-video-item theme-color--on-hover"
   href="#"
   (click)="onVideoClick($event)"
   data-ideals="helpVideoItem">
   <span class="video-icon-wrapper">
     <span class="video-icon">
       <i class="video-icon__play icons-play-filled"></i>
     </span>
  </span>
  <span class="video-title"
        idealsOverflow
        [ideals-tooltip]="video?.title">
    {{video?.title}}
  </span>
</a>
