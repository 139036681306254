import {ValidatorFn, Validators} from '@angular/forms';
import {Observable} from 'rxjs';

import {Validator} from '../decorators/validator.decorator';
import {IValidatorOptions} from '../interfaces';
import {IValidatorError} from '../interfaces/validator-error.interface';
import {CustomValidators} from './custom-validators.class';

export class IdealsValidators {
  @Validator(Validators.min)
  public static min: (min: number, options: {error: IValidatorError}) => ValidatorFn;
  @Validator(Validators.max)
  public static max: (max: number, options: {error: IValidatorError}) => ValidatorFn;
  @Validator(Validators.minLength)
  public static minLength: (minLength: number, options: {error: IValidatorError}) => ValidatorFn;
  @Validator(Validators.maxLength)
  public static maxLength: (maxLength: number, options: {error: IValidatorError}) => ValidatorFn;
  @Validator(Validators.pattern)
  public static pattern: (pattern: string | RegExp, options: {error: IValidatorError}) => ValidatorFn;
  @Validator(Validators.required)
  public static required: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(Validators.requiredTrue)
  public static requiredTrue: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(Validators.email)
  public static email: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(Validators.nullValidator)
  public static nullValidator: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.specCharValidator)
  public static specChar: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.whitespaceValidator)
  public static whitespace: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.confirmPasswordValidator)
  public static confirm: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.lowercaseLetter)
  public static lowercaseLetter: (lowercaseLetter: number, options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.uppercaseLetter)
  public static uppercaseLetter: (uppercaseLetter: number, options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.allCharsValid)
  public static allCharsValid: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.digitOrSpecChars)
  public static digitOrSpecChars: (digitOrSpecChars: number, options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.digitsAndLatinOnly)
  public static digitsAndLatinOnly: (options: {error: IValidatorError}) => ValidatorFn;
  @Validator(CustomValidators.emailAddressValidator)
  public static emailAddress: (options: {error: IValidatorError}) => ValidatorFn;

  public static getArrayValidators(options: Array<{validatorName: string; params: IValidatorOptions}>): Array<ValidatorFn> {
    return options.map((option) => IdealsValidators[option.validatorName](option.params));
  }

  public static getRequiredWhitespaceSpecCharValidator(
    requireErrorText: Observable<string>,
    specCharErrorText: Observable<string>,
    whitespaceErrorText?: Observable<string>
  ): Array<ValidatorFn> {
    return [
      ...IdealsValidators.getArrayValidators([{validatorName: 'specChar', params: {validatorParameters: [], error: {text: specCharErrorText}}}]),
      ...IdealsValidators.getRequiredWhitespaceValidator(requireErrorText, whitespaceErrorText)
    ];
  }

  public static getRequiredWhitespaceValidator(requireErrorText: Observable<string>, whitespaceErrorText?: Observable<string>): Array<ValidatorFn> {
    const whitespaceText = whitespaceErrorText ? whitespaceErrorText : requireErrorText;

    return [
      ...IdealsValidators.getRequiredValidator(requireErrorText),
      ...IdealsValidators.getArrayValidators([{validatorName: 'whitespace', params: {validatorParameters: [], error: {text: whitespaceText}}}])
    ];
  }

  public static getRequiredValidator(requireErrorText: Observable<string>): Array<ValidatorFn> {
    return IdealsValidators.getArrayValidators([{validatorName: 'required', params: {validatorParameters: [], error: {text: requireErrorText}}}]);
  }
}
