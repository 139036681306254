import {Injectable} from '@angular/core';

import {IUnleashConfig} from '@ideals/types';
import {from, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {InMemoryStorageProvider, UnleashClient} from 'unleash-proxy-client';

import {switchMapToVoid, VOID} from './utils/switch-map-to-void.util';

const DEFAULT_CONTEXT = 'root';

@Injectable({providedIn: 'root'})
export class FeatureToggleService {
  private readonly _clients = new Map<string, UnleashClient>();

  _config?: IUnleashConfig;

  initialize(config: IUnleashConfig): Observable<void> {
    if (this._config) {
      throw new Error('FeatureFlags service already initialized');
    }

    this._config = config;

    return from(this._getClient(DEFAULT_CONTEXT))
      .pipe(
        switchMapToVoid,
        catchError(() => VOID),
      );
  }

  isEnabled(flag: string): boolean {
    return (this._clients.get(DEFAULT_CONTEXT)
      ?.isEnabled(flag) ?? false);
  }

  isEnabledWithContext(flag: string, context: string): Observable<boolean> {
    return from(this._getClient(this._normalizeContext(context)))
      .pipe(
        map((client) => client.isEnabled(flag)),
        catchError(() => of(false)),
      );
  }

  private _normalizeContext(context: string): string {
    const [prefix, domain] = context.split('@');

    return domain ? [
      prefix
        .split('+')
        .pop(),
      domain
    ].join('@') : context;
  }

  private async _getClient(context: string): Promise<UnleashClient> {
    if (!this._config) {
      return Promise.reject();
    }

    if (this._clients.has(context)) {
      return this._clients.get(context);
    }

    const client = new UnleashClient({
      appName: this._config.instanceId,
      clientKey: this._config.proxySecret,
      disableMetrics: true,
      disableRefresh: true,
      storageProvider: new InMemoryStorageProvider(),
      url: this._config.url,
    });

    if (context !== DEFAULT_CONTEXT) {
      await client.updateContext({ userId: context });
    }

    await client.start();

    this._clients.set(context, client);

    return client;
  }
}
