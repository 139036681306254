import {Directive, HostListener, Inject} from '@angular/core';
import {WINDOW} from '@ideals/types';

@Directive({
  selector: '[idealsOpenMailNewTab]'
})

export class OpenMailNewTabDirective {
  constructor(@Inject(WINDOW) private readonly _window: Window) {
  }

  @HostListener('click', ['$event'])
  public mailTo(event: MouseEvent): void {
    this._window.open((event.target as HTMLAnchorElement).href, 'Mail');
    event.preventDefault();
  }
}
