import { IAppConfig } from '@ideals/types';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    readonly environment: IAppConfig;
  }
}

export const environment: IAppConfig = window.environment;
