export namespace PLACEMENT {
  export const AutoStart = 'auto-start';
  export const Auto = 'auto';
  export const AutoEnd = 'auto-end';
  export const TopStart = 'top-start';
  export const Top = 'top';
  export const TopEnd = 'top-end';
  export const RightStart = 'right-start';
  export const Right = 'right';
  export const RightEnd = 'right-end';
  export const BottomEnd = 'bottom-end';
  export const Bottom = 'bottom';
  export const BottomStart = 'bottom-start';
  export const LeftEnd = 'left-end';
  export const Left = 'left';
  export const LeftStart = 'left-start';
}
