import {ChangeDetectorRef, Directive, OnInit} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective} from '@angular/forms';
import {ControlErrorsScope} from '../enums';
import {INgSelectSearchEvent, IValidatorError} from '../interfaces';
import {InputComponentBasicBindings} from './input-component-basic-bindings.class';

@Directive()
export class PersistentInputComponent extends InputComponentBasicBindings implements ControlValueAccessor, OnInit {
  public readonly parentFormGroup: FormGroupDirective;
  public readonly changeDetectorRef: ChangeDetectorRef;

  private _controlValueAccessorChangeFn = (value: any): void => {};

  constructor(
    parentFormGroup?: FormGroupDirective,
    changeDetectorRef?: ChangeDetectorRef,
  ) {
    super();
    this.parentFormGroup = parentFormGroup;
    this.changeDetectorRef = changeDetectorRef;
  }

  public ngOnInit(): void {
    if (this.parentFormGroup && !this.formControl) {
      const controlName = this.parentControlName
        ? `${this.parentControlName}.${this.formControlName as string}`
        : this.formControlName as string || this.formArrayName;
      this.formControl = this.parentFormGroup.control.get(controlName);
    }
  }

  public registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public writeValue(value: any): void {
    this.value = value;

    if (this.changeDetectorRef) {
      this.changeDetectorRef.detectChanges();
    }
  }

  public modelChange(value: any): void {
    this.doChange(value);
  }

  public doSearch(event: INgSelectSearchEvent<any>): void {
    this.searchForItem.emit(event);
  }

  public onKeyUpEnter(): void {
    this.keyUpEnter.emit(this.value);
  }

  public doBlur($event: any): void {
    this.controlBlur.emit($event);
  }

  public doFocus($event: any): void {
    this.controlFocus.emit($event);
  }

  public doChange(value: any): void {
    this.value = value;
    this._controlValueAccessorChangeFn(value);
    this.valueChange.emit(value);
  }

  public get errorList(): Array<IValidatorError> {
    const {errors} = this.formControl ? this.formControl : this;

    return Object.keys(errors ? errors : {})
      .sort((keyA, keyB) => errors[keyA].priority - errors[keyB].priority)
      .filter((item, index) => this.errorsToShow === ControlErrorsScope.FirstOnly ? index === 0 : true)
      .map((item) => errors[item]);
  }
}
