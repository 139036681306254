import {registerLocaleData} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';
import localeUk from '@angular/common/locales/uk';
import localeZh from '@angular/common/locales/zh';
import {LOCALE_ID, NgModule} from '@angular/core';
import {Cultures, IStringMap} from '@ideals/types';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

export class LocalizeTranslateLoader implements TranslateLoader {
  constructor(
    private readonly _http: HttpClient
  ) {
  }

  public getTranslation(lang: string): Observable<IStringMap> {
    return this._http.get<IStringMap>(`/localization/${lang}.json`)
      .pipe(
        catchError(() => of({}))
      );
  }
}

export class LocaleId extends String {
  constructor(private readonly _translateService: TranslateService) {
    super();
  }

  toString(): string {
    return this._translateService.currentLang;
  }

  valueOf(): string {
    return this._translateService.currentLang;
  }
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LocalizeTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useClass: LocaleId,
      deps: [TranslateService]
    }
  ],
  exports: [
    TranslateModule
  ]
})
export class RootTranslateModule {
  constructor() {
    // to localize dates with '@angular/common/formatDate' (Date.toLocaleString() isn't supported in IE 10)
    registerLocaleData(localeDe, Cultures.de_DE);
    registerLocaleData(localeEn, Cultures.en_US);
    registerLocaleData(localeEs, Cultures.es_ES);
    registerLocaleData(localeFr, Cultures.fr_FR);
    registerLocaleData(localeIt, Cultures.it_IT);
    registerLocaleData(localeJa, Cultures.ja_JP);
    registerLocaleData(localeKo, Cultures.ko_KR);
    registerLocaleData(localeNl, Cultures.nl_NL);
    registerLocaleData(localePl, Cultures.pl_PL);
    registerLocaleData(localePt, Cultures.pt_BR);
    registerLocaleData(localeRu, Cultures.ru_RU);
    registerLocaleData(localeSv, Cultures.sv_SE);
    registerLocaleData(localeTr, Cultures.tr_TR);
    registerLocaleData(localeUk, Cultures.uk_UA);
    registerLocaleData(localeZh, Cultures.zh_CN);
  }
}
