import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Alerts, IAlert } from './models';
import { PageMessagesService } from './page-messages.service';

@Component({
  selector: 'ideals-page-message',
  templateUrl: './page-messages.component.html',
  styleUrls: ['./page-messages.component.scss']
})
export class PageMessagesComponent {
  constructor(private readonly _pageMessages: PageMessagesService) {}

  public get messages$(): Observable<Alerts> {
    return this._pageMessages.messages$;
  }

  public trackById(_: number, alert: IAlert): string {
    return alert.id;
  }
}
