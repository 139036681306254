import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {DOCUMENT} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, OnDestroy, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {PersistentComponent, Position, WINDOW} from '@ideals/types';
import {Utils} from '@ideals/utils';
import {NgSelectComponent} from '@ng-select/ng-select';
import {Store} from '@ngrx/store';
import {CookieService} from 'ngx-cookie-service';
import {map, startWith} from 'rxjs/operators';
import {HelpCountry} from '../../models/classes';
import {COUNTRY_CODE_COOKIE} from '../../models/constants';
import {IHelpCountry} from '../../models/interfaces';
import {IHelpState} from '../../store/help-state.interface';
import {setSelectedCountryAction} from '../../store/help.actions';
import {selectCountries, selectCountriesLoading, selectSelectedCountry} from '../../store/help.getters';

export const COUNTRIES_GROUP_BY_FIELD = 'regionName';
const DROPDOWN_PANEL_SELECTOR = '.ng-dropdown-panel';
const SCROLLED_CLASS = 'scrolled';

@Component({
  selector: 'ideals-help-contacts',
  templateUrl: './help-contacts.component.html',
  styleUrls: ['./help-contacts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpContactsComponent extends PersistentComponent implements OnDestroy {
  @ViewChild('countriesSelect')
  public countriesSelect: NgSelectComponent;

  public readonly countriesGroupByField = COUNTRIES_GROUP_BY_FIELD;

  public loading$ = this._helpStore.select(selectCountriesLoading);
  public countries$ = this._helpStore.select(selectCountries);
  public selectedCountry$ = this._helpStore.select(selectSelectedCountry);

  public readonly dropdownPosition$ = this._breakpointObserver.observe('(min-width: 1280px)')
    .pipe(
      map((state: BreakpointState) => state.matches ? 'bottom' : 'auto'),
      startWith('auto')
    );

  private _eventScrollOptions = {capture: true, passive: true};

  private _adjustDropdownPosition = (): void => {
    this.countriesSelect.dropdownPanel.adjustPosition();
  }

  private _hideDropdown = (): void => {
    this.countriesSelect.close();
  }

  constructor(
    private readonly _helpStore: Store<IHelpState>,
    private readonly _cookieService: CookieService,
    @Inject(WINDOW) private readonly _window: Window,
    private readonly _breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _renderer: Renderer2
  ) {
    super();
  }

  public phones(contacts: string): Array<string> {
    return contacts.split(/\n/);
  }

  public searchCountry(term: string, {countryName, phoneCode}: IHelpCountry): boolean {
    const query = term.toLocaleLowerCase();
    const value = `${countryName}${phoneCode}`.toLocaleLowerCase();

    return value.indexOf(query) > -1;
  }

  public selectCountry(selectedCountry: IHelpCountry): void {
    if (selectedCountry instanceof HelpCountry) {
      this._cookieService.set(COUNTRY_CODE_COOKIE, selectedCountry.countryCode, undefined, '/', Utils.domain, true);
      this._helpStore.dispatch(setSelectedCountryAction({selectedCountry}));
    }
  }

  public onSelectOpen(): void {
    if (this.countriesSelect) {

      setTimeout(() => {
        const maxElementHeight = 269;
        const {dropdownPanel: {scrollElementRef: {nativeElement}}} = this.countriesSelect;

        if (this.countriesSelect.currentPanelPosition === Position.Bottom) {
          const dropdownTop = nativeElement.getBoundingClientRect().top;
          const bottomMargin = 5;
          const heightToBottom = this._window.innerHeight - dropdownTop - bottomMargin;
          const maxHeight = Math.min(maxElementHeight, heightToBottom);
          nativeElement.style.setProperty('max-height', `${maxHeight}px`, 'important');
        } else {
          nativeElement.style.setProperty('max-height', `${maxElementHeight}px`, 'important');
        }

        nativeElement.scrollTop = 0;
        this._addWindowListeners();

        const panel = this._document.querySelector(DROPDOWN_PANEL_SELECTOR);
        this._renderer.addClass(panel, SCROLLED_CLASS);
      }, 0);
    }
  }

  public onSelectClose(): void {
    this._removeWindowListeners();
  }

  public ngOnDestroy(): void {
    this._removeWindowListeners();
  }

  private _addWindowListeners(): void {
    this._window.addEventListener('scroll', this._adjustDropdownPosition, this._eventScrollOptions);
    // adjustPosition does not update X axis
    this._window.addEventListener('orientationchange', this._hideDropdown);
  }

  private _removeWindowListeners(): void {
    this._window.removeEventListener('scroll', this._adjustDropdownPosition, this._eventScrollOptions);
    this._window.removeEventListener('orientationchange', this._hideDropdown);
  }
}
