import {Component} from '@angular/core';
import {SpinnerService} from '@ideals/services/spinner';
import {PersistentObservableComponent} from '@ideals/types';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ideals-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent extends PersistentObservableComponent {
  public showSpinner: boolean;

  constructor(private _spinnerService: SpinnerService) {
    super();
    this._spinnerService.spinnerCounter
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((showSpinner) => this.showSpinner = showSpinner);
  }
}
