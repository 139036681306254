import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '@ideals/types';

export const HEAP_TRACK_EVENTS = [
  'addEventProperties',
  'addUserProperties',
  'clearEventProperties',
  'identify',
  'resetIdentity',
  'removeEventProperty',
  'setEventProperties',
  'track',
  'unsetEventProperty'
];

@Injectable({
  providedIn: 'root'
})
export class HeapService {
  private _heapLoaded = false;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: Window,
  ) {
  }

  public runHeap(heapId: string): void {
    if (!this._heapLoaded) {
      this._heapLoaded = true;

      (this._window as any).heap = (this._window as any).heap || [];
      const heap = (this._window as any).heap;
      heap.load = this._loadHeap.bind(this);

      heap.load(heapId);
    }
  }

  private _loadHeap(id: string, config: Object = {}): void {
    const heap = (this._window as any).heap;

    heap.appid = id;
    heap.config = config;

    const script = this._document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.heapanalytics.com/js/heap-${id}.js`;
    this._document.body.appendChild(script);

    HEAP_TRACK_EVENTS.forEach((item) => {
      heap[item] = (...args): void => heap.push([item].concat(Array.prototype.slice.call(args, 0)));
    });
  }
}
