<a href="#"
   class="help-button"
   (click)="openHelpModal($event)"
   data-ideals="showHelpModal">
  <span #content class="help-button__custom">
    <ng-content></ng-content>
  </span>
  <ng-container *ngIf="!content.childNodes.length">
    <i class="icon icons-question"></i>
    <span class="title">{{'common.BTN.help' | translate}}</span>
  </ng-container>
</a>
