import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {PersistentComponent} from './persistent-component.class';

@Directive()
export abstract class PersistentObservableComponent extends PersistentComponent implements OnDestroy {
  public componentDestroyed = new Subject<void>();
  protected _subscribers: Array<any> = [];

  public ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this._subscribers.forEach((subscriber) => subscriber.unsubscribe());
  }
}
