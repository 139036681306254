import {HttpParams} from '@angular/common/http';

interface IParams {
  [param: string]: any;
}

export class Utils {
  public static get domain(): string {
    const slice = -2;

    return location.host
      .replace(/:\d+/, '')
      .split('.')
      .slice(slice)
      .join('.');
  }

  public static get getUniqueId(): string {
    return Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - (new Date().getTime())))
      .toString();
  }

  public static openUrlInNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  public static openUrlInThisWindow(url: string): void {
    window.open(url, '_self');
  }

  public static blurActiveElement(): void {
    if (document && document.activeElement) {
      (document.activeElement as HTMLInputElement).blur();
    }
  }

  public static isDefined(value: any): boolean {
    return typeof value !== 'undefined';
  }

  // TODO move method from Utils to avoid HttpParams import here
  public static objectToHttpParams(params: IParams): HttpParams {
    return Object.keys(params)
      .reduce((res, key) => {
        const value = params[key];

        if (Array.isArray(value)) return value.reduce((acc, id) => acc.append(key, id.toString()), res);

        if (Utils.isDefined(value)) return res.append(key, value.toString());

        return res;

      }, new HttpParams());
  }

  public static objectToQueryString(params: IParams, url: string = '', encode: boolean = true): string {
    const queryString = Object.keys(params)
      .filter((key) => Utils.isDefined(params[key]))
      .map((key) => encode ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : `${key}=${params[key]}`)
      .join('&');

    return `${url}?${queryString}`;
  }

  public static queryParamsToObject(url: string): URLSearchParams {
    const [_, queryString] = url.split('?');

    return new URLSearchParams(queryString);
  }

  public static getPathVariableByPosition(position: number): string {
    const pathname = location.pathname;

    return pathname
      .split('/')
      .filter((item) => !!item)[position];
  }

  public static uppercaseFirstLetter(value: string): string {
    return value.charAt(0)
      .toUpperCase() + value.slice(1);
  }

  public static lowercaseFirstLetter(value: string): string {
    return value.charAt(0)
      .toLowerCase() + value.slice(1);
  }

  public static isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  public static isString(value: any): boolean {
    return typeof value === 'string';
  }

  public static isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  public static parserIntWithRadix10(value: string): number {
    return parseInt(value, 10);
  }

  public static trimAndLowerCaseString(value: string = ''): string {
    return value.trim()
      .toLowerCase();
  }

  public static compare(a: number | string, b: number | string, isAsc?: boolean, lowerCase?: boolean): number {
    const compareInLowerCase = Utils.isString(a) && Utils.isString(b) && lowerCase;
    let first = a;
    let second = b;

    if (compareInLowerCase) {
      first = `${a}`.toLowerCase();
      second = `${b}`.toLowerCase();
    }

    return first === second ? 0 : (first < second ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public static convertMegabytesToGigabytes(value: number): number {
    const megabytesInGigabyte = 1000;

    return value / megabytesInGigabyte;
  }

  public static removeParamFromUrl(url: string, param: string): string {
    const targetUrl = new URL(url);
    targetUrl.searchParams.delete(param);

    return targetUrl.toString();
  }
}
