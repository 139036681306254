import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {extModules} from '../../build-specifics';
import * as app from './reducers/app.reducers';

@NgModule({
  imports: [
    StoreModule.forRoot({app: app.reducer}),
    EffectsModule.forRoot([]),
    ...extModules
  ],
  exports: [StoreModule],
})
export class AppStoreModule {
}
