type MessageHandler =
  'changeDomain' |
  'changeThemeBackground' |
  'changeThemeColor' |
  'download' |
  'openExternalUrl' |
  'writeToClipboard';

interface IPostMessage {
  readonly postMessage: (data: string) => void;
}

interface ICustomWindow extends Window {
  readonly flutter_inappwebview: {
    readonly callHandler: (method: MessageHandler, data: string) => void;
  };
  readonly webkit: {
    readonly messageHandlers: Record<MessageHandler, IPostMessage>;
  };
}

export function sendMessageToDevice(method: MessageHandler, data: string): void {
  (window as unknown as ICustomWindow).flutter_inappwebview?.callHandler?.(method, data);
  (window as unknown as ICustomWindow).webkit?.messageHandlers?.[method]?.postMessage?.(data);
}
