import {Directive, Input} from '@angular/core';
import {IIdentity} from '../interfaces';
import {Persistent} from './persistent.class';

@Directive()
export abstract class PersistentComponent extends Persistent {
  @Input()
  public set value(value: any) {
    this._val = value;
  }

  public get value(): any {
    return this._val;
  }

  @Input()
  public disabled = false;

  public get id(): string {
    return this.componentId;
  }

  public set id(value: string) {
    this.componentId = value;
  }

  private _val: any;

  constructor() {
    super();
    Persistent.setPrototypeOf(this, new.target.prototype);
  }

  public trackById(_: number, identity: IIdentity): string {
    return identity.id;
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public trackByItem(_: number, item: any): any {
    return item;
  }
}
