import {ComponentType} from '@angular/cdk/portal';
import {Injectable, TemplateRef} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class IdealsDialogService {
  constructor(private readonly _matDialog: MatDialog) {
  }

  public open<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    const dialogRef = this._matDialog.open(componentOrTemplateRef, config);

    this._addAnimationOnClose(dialogRef);

    return dialogRef;
  }

  private _addAnimationOnClose<T>(dialogRef: MatDialogRef<T>): void {
    const animationDuration = 150;
    const dialogRefClose = dialogRef.close.bind(dialogRef);

    dialogRef.close = (dialogResult): void => {
      dialogRef.addPanelClass('fade');
      setTimeout(() => {
        dialogRefClose(dialogResult);
      }, animationDuration);
    };
  }
}
