export enum Cultures {
  en_US = 'en-US',
  zh_CN = 'zh-CN',
  de_DE = 'de-DE',
  sv_SE = 'sv-SE',
  fr_FR = 'fr-FR',
  es_ES = 'es-ES',
  pt_BR = 'pt-BR',
  it_IT = 'it-IT',
  nl_NL = 'nl-NL',
  ja_JP = 'ja-JP',
  ko_KR = 'ko-KR',
  tr_TR = 'tr-TR',
  pl_PL = 'pl-PL',
  ru_RU = 'ru-RU',
  uk_UA = 'uk-UA'
}
