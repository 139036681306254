import {IPasswordComplexity, ITimeZone, TwoFaMethods} from '@ideals/types';
import {createAction, props} from '@ngrx/store';
import {IPhone2fa, ProfileInfo} from '../../types';

export const APP_ACTIONS = {
  SET_PROFILE_INFO: '[AUTH] Set Personal Info',
  SET_PASSWORD_COMPLEXITY: '[AUTH] Set Password Complexity',
  SET_TIME_ZONE: '[AUTH] Set Time Zone',
  SET_PHONE_NUMBER: '[AUTH] Set Phone Number',
  SET_EMAIL: '[AUTH] Set Email',
  SET_USER_2FA: '[AUTH] Set User 2FA',
  SET_2FA_METHODS: '[AUTH] Set 2FA Methods',
  RESET_STORE: '[AUTH] Reset store'
};

export const setProfileInfoAction = createAction(APP_ACTIONS.SET_PROFILE_INFO, props<{profileInfo: ProfileInfo}>());
export const set2faAction = createAction(APP_ACTIONS.SET_USER_2FA, props<{require2fa: IPhone2fa}>());
export const setPhoneNumberAction = createAction(APP_ACTIONS.SET_PHONE_NUMBER, props<{phone: string}>());
export const setEmailAction = createAction(APP_ACTIONS.SET_EMAIL, props<{email: string}>());
export const setPasswordComplexityAction = createAction(APP_ACTIONS.SET_PASSWORD_COMPLEXITY, props<{passwordRules: Array<IPasswordComplexity>}>());
export const setTimeZoneAction = createAction(APP_ACTIONS.SET_TIME_ZONE, props<{timeZone: ITimeZone}>());
export const set2FaMethodsAction = createAction(APP_ACTIONS.SET_2FA_METHODS, props<{twoFaMethods: Array<TwoFaMethods>}>());
export const resetStore = createAction(APP_ACTIONS.RESET_STORE);
