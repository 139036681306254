import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'ideals-help-article',
  templateUrl: './help-article.component.html',
  styleUrls: ['./help-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpArticleComponent {
  @Input()
  public title: string;

  @Input()
  public url: string;
}
