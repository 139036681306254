import {Injectable} from '@angular/core';

interface IHelpAuthData {
  readonly isLoggedIn: boolean;
  readonly isIdealsUser: boolean;
}

@Injectable({providedIn: 'root'})
export class HelpAuthDataService {
  private _isLoggedIn = false;
  private _isIdealsUser = false;

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public get isIdealsUser(): boolean {
    return this._isIdealsUser;
  }

  public updateData({isLoggedIn, isIdealsUser}: IHelpAuthData): void {
    this._isLoggedIn = isLoggedIn;
    this._isIdealsUser = isIdealsUser;
  }
}
