<ng-container *ngIf="loading$ | async; else contacts">
  <ideals-skeleton class="skeleton" height="54px"></ideals-skeleton>
</ng-container>

<ng-template #contacts>
  <div class="help-contacts"
      *ngIf="selectedCountry$ | async as selectedCountry"
      data-ideals="helpContacts">

    <ng-select #countriesSelect
              class="help-contacts__countries ng-select__arrow--fixed ideals-select__theme--colored-arrow ideals-select__theme--colored-value"
              *ngIf="countries$ | async as countries"
              [items]="countries"
              [clearable]="false"
              [searchable]="false"
              [openOnEnter]="false"
              [searchWhileComposing]="false"
              [groupBy]="countriesGroupByField"
              [searchFn]="searchCountry"
              [ngModel]="selectedCountry"
              [dropdownPosition]="dropdownPosition$ | async"
              appendTo="body"
              (change)="selectCountry($event)"
              (open)="onSelectOpen()"
              (close)="onSelectClose()"
              data-ideals="helpContactsSelect">
      <ng-template ng-label-tmp let-item="item">
        <div class="help-contacts__selected-country">
          <i class="icons-phone"></i>
          <span class="value" idealsOverflow [ideals-tooltip]="selectedCountry.countryName">
            {{selectedCountry.countryName}}
          </span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div class="help-contacts__country">
          <span class="help-contacts__country-flag" [ngClass]="item.countryCode"></span>
          <span class="country-name">{{item.countryName}}</span>
          <span class="country-phone-code">({{item.phoneCode}})</span>
        </div>
      </ng-template>

      <ng-template ng-header-tmp>
        <div class="help-contacts__search" data-ideals="helpContactsSearch">
          <label class="help-contacts__search--label icons-search"
                for="search"
                data-ideals="helpContactsSearchLabel">
          </label>
          <input id="search"
                class="help-contacts__search--input"
                type="text"
                autocomplete="off"
                idealsAutofocus
                (input)="countriesSelect.filter($event?.target?.value)"
                data-ideals="helpContactsSearchInput" />
        </div>
      </ng-template>
      <ng-template ng-footer-tmp>
        <div class="help-contacts__search" data-ideals="helpContactsSearch">
          <label class="help-contacts__search--label icons-search"
                for="search"
                data-ideals="helpContactsSearchLabel">
          </label>
          <input id="search"
                class="help-contacts__search--input"
                type="text"
                autocomplete="off"
                idealsAutofocus
                (input)="countriesSelect.filter($event?.target?.value)"
                data-ideals="helpContactsSearchInput" />
        </div>
      </ng-template>
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm"></ng-template>
    </ng-select>
    <p class="help-contacts__phones" *ngIf="selectedCountry">
      <span *ngFor="let phone of phones(selectedCountry.contacts); trackBy: trackByIndex"
            idealsOverflow
            [ideals-tooltip]="selectedCountry.contacts"
            [tooltipWidthAuto]="true">
        {{phone}}
      </span>
    </p>
  </div>
</ng-template>
