import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslatedContentDirective} from './translated-content.directive';
import {TranslatedWrapperDirective} from './translated-wrapper.directive';

const directives = [TranslatedWrapperDirective, TranslatedContentDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives
})
export class TranslatedContentModule {
}
