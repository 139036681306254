import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {sendMessageToDevice} from '@ideals/utils';
import {CookieService} from 'ngx-cookie-service';

const THEME_DARK_KEY = 'ideals-theme-dark';

@Injectable({providedIn: 'root'})
export class ColorSchemeService {
  readonly isDarkMode: boolean;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _cookieService: CookieService
  ) {
    this.isDarkMode = this._cookieService.check(THEME_DARK_KEY)
      ? JSON.parse(this._cookieService.get(THEME_DARK_KEY)) as boolean
      : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  public initialize(): void {
    this._document.body.classList.add(this.isDarkMode ? 'dark' : 'light');
    setTimeout(() => {
      sendMessageToDevice('changeThemeBackground', this.isDarkMode ? '#1F2129' : '#FFFFFF');
      // eslint-disable-next-line no-magic-numbers
    }, 350);
  }
}
