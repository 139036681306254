import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {NAVIGATOR, WINDOW} from '@ideals/types';
import {Utils} from '@ideals/utils';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

interface IParams {
  [param: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private readonly _http: HttpClient,
    @Inject(WINDOW) private readonly _window: Window & typeof globalThis,
    @Inject(NAVIGATOR) private readonly _navigator: Window['navigator']
  ) {
  }

  public downloadFile(url: string, fileName: string, httpParams: IParams = {}): Observable<Blob> {
    const params = Utils.objectToHttpParams(httpParams);

    return this._http
      .get(url, {responseType: 'blob', params})
      .pipe(
        tap((blob) => {
          const a = document.createElement('a');
          a.href = this._window.URL.createObjectURL(blob);
          a.download = fileName;
          a.dispatchEvent(new MouseEvent('click'));
        }),
      );
  }
}
