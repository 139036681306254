import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FeatureToggleService} from '@ideals/services/feature-toggle';

@Component({
  selector: 'ideals-help-wrapper',
  templateUrl: './help-wrapper.component.html',
  styleUrls: ['./help-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpWrapperComponent {
  @Input()
  public hideNew: boolean;

  public isNewHelpModal = this._featureToggleService.isEnabled('is-new-help-modal');

  constructor(private readonly _featureToggleService: FeatureToggleService) {
  }
}
