import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { appConfig } from './app/app.config';
import { RootComponent } from './app/root.component';
import { environment } from './environments/environment';

import './instrument';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RootComponent, appConfig)
  .catch((error) => console.error(error));
