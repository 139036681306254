<div mat-dialog-title data-ideals="supportModalHeader">
  <button class="close icons-cross"
          type="button"
          mat-dialog-close
          [attr.aria-label]="'Close' | translate"
          [attr.data-ideals]="'close'"
          (click)="closeModal()"
          data-ideals="dismissModal">
  </button>
  <h4 class="modal-title">{{"common.BTN.help" | translate}}</h4>
</div>
<mat-tab-group class="with-header"
               mat-dialog-content
               [@.disabled]="true"
               [selectedIndex]="activeTabIndex"
               data-ideals="supportModalContainer">
  <mat-tab *ngIf="!data.isContactsHidden">
    <ng-template mat-tab-label>
      <div class="ideals-text-container__theme tab-heading-holder" data-ideals="showTabIdealsCustomerSuccessTeam">
        <i class="icon icons-headset"></i>
        <span>{{"iDealsCustomerSupportTeam" | translate}}</span>
      </div>
    </ng-template>
    <div class="tab-section">
      <div class="tab-section-content chat-support" *ngIf="intercomChat">
        <h3 class="chat-support__title">{{"LiveChatSupport24/7" | translate}}</h3>
        <div class="content-holder chat-support__content">
          <p>{{"AverageResponseTimeIs30Sec" | translate}}</p>
          <p>{{"Phrases.ChatFeatures" | translate}}</p>
          <a class="open-chat"
             idealsShowChat
             data-ideals="supportModalOpenChat">
            <i class="icons-chat"></i>
            {{"ChatWithUsNow" | translate}}
          </a>
        </div>
      </div>
      <div class="tab-section-content">
        <h3>{{"EmailUs" | translate}}</h3>
        <div class="email-support content-holder">
          <a class="ideals-text-container__theme"
             href="mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#105;&#100;&#101;&#097;&#108;&#115;&#118;&#100;&#114;&#046;&#099;&#111;&#109;"
             target="_blank"
             [attr.data-ideals]="'helpSupportEmail'"
             data-ideals="emailSupportLink">
            <i class="icons-letter"></i>
            <span class="email">
                  &#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#105;&#100;&#101;&#097;&#108;&#115;&#118;&#100;&#114;&#046;&#099;&#111;&#109;
                </span>
          </a>
        </div>
      </div>
      <div class="tab-section-content" *ngIf="!data.isPhoneSupportHidden">
        <h3>{{"CallUs" | translate}}</h3>
        <div class="contacts-columns content-holder">
          <div class="col">
            <h4>{{"Americas" | translate}}</h4>
            <dl>
              <dt>{{"US" | translate}}</dt>
              <dd>+1 646 905 0442<br>888 927 0491 ({{"TollFree" | translate}})</dd>
              <dt>{{"Canada" | translate}}</dt>
              <dd>+1 647 694 5530<br>888 462 3718 ({{"TollFree" | translate}})</dd>
              <dt>{{"Brazil" | translate}}</dt>
              <dd>+55 11 3197 4092<br>800 878 1163 ({{"TollFree" | translate}})</dd>
              <dt>{{"Argentina" | translate}}</dt>
              <dd>+54 1 159 842 092</dd>
              <dt>{{"Colombia" | translate}}</dt>
              <dd>+57 15 085611</dd>
              <dt>{{"Mexico" | translate}}</dt>
              <dd>+52 55 5350 3464</dd>
            </dl>
          </div>
          <div class="col">
            <h4>{{"Europe" | translate}}</h4>
            <dl>
              <dt>{{"UK" | translate}}</dt>
              <dd>+44 (0) 20 3808 8839<br>808 189 5055 ({{"TollFree" | translate}})</dd>
              <dt>{{"Germany" | translate}}</dt>
              <dd>+49 30 8967 78644<br/>800 000 9594 ({{"TollFree" | translate}})</dd>
              <dt>{{"France" | translate}}</dt>
              <dd>+33 1 76 38 00 00</dd>
              <dt>{{"Russia" | translate}}</dt>
              <dd>+7 495 118 13 80<br>800 222 23 86 ({{"TollFree" | translate}})</dd>
              <dt>{{"Malta" | translate}}</dt>
              <dd>+356 27 78 08 13</dd>
              <dt>{{"Switzerland" | translate}}</dt>
              <dd>+41 43 508 9300</dd>
              <dt>{{"Ukraine" | translate}}</dt>
              <dd>+380 89 324 05 07</dd>
              <dt>{{"Spain" | translate}}</dt>
              <dd>+34 911 23 05 75</dd>
            </dl>
          </div>
          <div class="col">
            <h4>{{"MiddleEastAndAfrica" | translate}}</h4>
            <dl>
              <dt>{{"UAE" | translate}}</dt>
              <dd>+971 427 430 89<br>8000 357 702 91 ({{"TollFree" | translate}})</dd>
              <dt>{{"Israel" | translate}}</dt>
              <dd>+972 3 721 9407</dd>
            </dl>
          </div>
          <div class="col">
            <h4>{{"AsiaPacific" | translate}}</h4>
            <dl>
              <dt>{{"HongKong" | translate}}</dt>
              <dd>+852 5803 3181<br>800 931 235 ({{"TollFree" | translate}})</dd>
              <dt>{{"China" | translate}}</dt>
              <dd>+86 21 8036 9495</dd>
              <dt>{{"Japan" | translate}}</dt>
              <dd>+81 34 571 1851</dd>
              <dt>{{"Singapore" | translate}}</dt>
              <dd>+65 3163 1486</dd>
              <dt>{{"Australia" | translate}}</dt>
              <dd>+61 2 7202 4653<br>1800 469 308 ({{"TollFree" | translate}})</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="!data.unauthorizedUser">
    <ng-template mat-tab-label>
      <div class="tab-heading-holder" data-ideals="showTabIdealsHelpCenter">
        <i class="icon icons-book"></i>
        <span>{{"iDealsHelpCenter" | translate}}</span>
      </div>
    </ng-template>
    <div class="tab-section">
      <div class="tab-section-content">
        <h3>{{"iDealsHelpCenter" | translate}}</h3>
        <ul class="links-list content-holder">
          <li>
            <a target="_blank"
               [href]="helpLinks.urlKnowledgeBase"
               data-ideals="knowledgeBaseLink">
              <i class="icons-open-link"></i>
              <span>{{"ReadOurGuide" | translate}}</span>
            </a>
          </li>
          <li>
            <a target="_blank"
               [href]="helpLinks.UrlVideoTutorial"
               data-ideals="videoTutorialsLink">
              <i class="icons-play"></i>
              <span>{{"VideoTutorial" | translate}}</span>
            </a>
          </li>
          <li *ngIf="isDealOwner">
            <a target="_blank"
               [href]="helpLinks.urlAdministratorGuide"
               data-ideals="adminGuideLink">
              <i class="icons-pdf"></i>
              <span>{{"AdministratorGuide" | translate}}</span>
            </a>
          </li>
          <li>
            <a target="_blank"
               [href]="helpLinks.urlUserGuide"
               data-ideals="userGuideLink">
              <i class="icons-pdf"></i>
              <span>{{"UserGuide" | translate}}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-section-content" *ngIf="data.unauthorizedUser">
      <h3>{{"Troubleshooting" | translate}}</h3>
      <div class="tab-section-content">
        <ul class="links-list content-holder">
          <li>
            <a target="_blank" [href]="'ReasonsOfUnsuccessfulLoginLink' | translate" data-ideals="reasonsOfUnsuccessfulLogin">
              <i class="icons-open-link"></i>
              <span>{{"ReasonsOfUnsuccessfulLogin" | translate}}</span>
            </a>
          </li>
          <li>
            <a target="_blank" [href]="'HowToResetPasswordLink' | translate" data-ideals="howToResetPassword">
              <i class="icons-open-link"></i>
              <span>{{"HowToResetPassword" | translate}}</span>
            </a>
          </li>
          <li *ngIf="intercomChat">
            <span class="chat-help">{{"StillHavingTroubles" | translate}}</span>
            <a class="open-chat"
               idealsShowChat
               data-ideals="supportModalOpenChat">
              <i class="icons-chat"></i>
              {{"ChatWithUsNow" | translate}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    </div>
  </mat-tab>
</mat-tab-group>
