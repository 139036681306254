import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, InjectionToken } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { ClarityService } from '@ideals/services/clarity';
import { ColorSchemeService } from '@ideals/services/color-scheme';
import { FeatureToggleModule } from '@ideals/services/feature-toggle';
import { initLangFactory, RootTranslateModule } from '@ideals/services/root-translate';
import { APP_CONFIG, LOCATION } from '@ideals/types';

import { environment } from '../environments/environment';

import {
  BaseHrefInterceptor,
  LocalStorageService,
  PageTitleStrategy,
  ServerErrorsInterceptor,
  StorageService,
  UserStateErrorsInterceptor,
} from './core';
import { provideErrorHandler } from './core/services/error-handler';
import { AppStoreModule } from './store/app-store.module';

import { appRoutes } from './app.routes';

const BASED_ON_BROWSER_LANGUAGE = new InjectionToken<boolean>('BASED_ON_BROWSER_LANGUAGE');

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      AppStoreModule,
      RootTranslateModule,
      FeatureToggleModule.forRoot(environment.unleashConfiguration)
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(appRoutes),
    provideErrorHandler(),
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (location: Location) => location.origin,
      deps: [LOCATION],
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: StorageService,
      useClass: LocalStorageService,
    },
    {
      provide: BASED_ON_BROWSER_LANGUAGE,
      useValue: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseHrefInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserStateErrorsInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLangFactory,
      deps: [CookieService, TranslateService, BASED_ON_BROWSER_LANGUAGE],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (clarityService: ClarityService) => {
        return () => clarityService.initialize();
      },
      deps: [ClarityService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (colorSchemeService: ColorSchemeService) => {
        return () => colorSchemeService.initialize();
      },
      deps: [ColorSchemeService],
      multi: true,
    },
  ],
};
