import Popper from 'popper.js';

export class PopupContentOptions implements Popper.PopperOptions {
  public readonly disableDefaultStyling: boolean;
  public readonly placement: Popper.Placement;
  public readonly boundariesElement: string;
  public readonly trigger: Trigger;
  public readonly popupModifiers: Object;

  constructor(data: { disableDefaultStyling: boolean; placement: Popper.Placement; boundariesElement: string; trigger: Trigger; popupModifiers: Object }) {
    this.disableDefaultStyling = data.disableDefaultStyling;
    this.placement = data.placement;
    this.boundariesElement = data.boundariesElement;
    this.trigger = data.trigger;
    this.popupModifiers = {...data.popupModifiers};
  }
}

export type Trigger = 'click' | 'mousedown' | 'hover' | 'none';

export enum TRIGGERS {
  CLICK = 'click',
  HOVER = 'hover',
  MOUSEDOWN = 'mousedown',
  NONE = 'none'
}
