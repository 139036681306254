import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {IdealsDialogModule} from '@ideals/services/dialog';
import {AutofocusModule} from '@ideals/ui-components/autofocus';
import {OpenMailNewTabModule} from '@ideals/ui-components/open-mail-new-tab';
import {SkeletonModule} from '@ideals/ui-components/skeleton';
import {TooltipModule} from '@ideals/ui-components/tooltip';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {IntercomModule} from '../intercom/intercom.module';
import {PopupModule} from '../popup/popup.module';
import {HelpArticleComponent} from './components/help-article/help-article.component';
import {HelpButtonComponent} from './components/help-button/help-button.component';
import {HelpContactsComponent} from './components/help-contacts/help-contacts.component';
import {HelpModalComponent} from './components/help-modal/help-modal.component';
import {HelpSearchComponent} from './components/help-search/help-search.component';
import {HelpVideoItemComponent} from './components/help-video-item/help-video-item.component';
import {HelpWrapperComponent} from './components/help-wrapper/help-wrapper.component';
import {HelpButtonOldComponent} from './help-button-old/help-button-old.component';
import {HelpModalOldComponent} from './help-modal-old/help-modal-old.component';
import {HelpStoreModule} from './store/help-store.module';

const components = [
  HelpButtonComponent,
  HelpModalComponent,
  HelpButtonOldComponent,
  HelpModalOldComponent,
  HelpSearchComponent,
  HelpArticleComponent,
  HelpVideoItemComponent,
  HelpContactsComponent,
  HelpWrapperComponent,
];

@NgModule({
  imports: [
    CommonModule,
    IdealsDialogModule,
    MatTabsModule,
    TranslateModule,
    TooltipModule,
    FormsModule,
    PopupModule,
    NgSelectModule,
    HelpStoreModule,
    OpenMailNewTabModule,
    AutofocusModule,
    IntercomModule,
    SkeletonModule,
  ],
  declarations: components,
  exports: [HelpWrapperComponent, HelpButtonOldComponent, HelpButtonComponent],
  providers: [CookieService]
})
export class HelpModule {
}
