import {Injectable} from '@angular/core';
import {IStringMap, ITimeZone} from '@ideals/types';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {MemoizedSelector} from '@ngrx/store/src/selector';
import {CookieService} from 'ngx-cookie-service';
import {combineLatest, Observable, of} from 'rxjs';
import {first, map, switchMap, tap} from 'rxjs/operators';
import {HelpService} from '../help.service';
import {COUNTRY_CODE_COOKIE, DEFAULT_COUNTRY_CODE} from '../models/constants';
import {Articles, HelpCountries, Videos} from '../models/types';
import {IHelpState} from './help-state.interface';
import {
  loadArticlesAndVideosAction,
  loadCountriesAction,
  setArticlesAction,
  setCountriesAction,
  setCountryPhonesMappingAction,
  setFilteredArticlesAction,
  setFilteredVideosAction,
  setSelectedCountryAction,
  setUserLocationAction,
  setVideosAction,
} from './help.actions';
import {selectAppPage, selectArticles, selectCountries, selectCountryPhonesMapping, selectUserLocation, selectVideos} from './help.getters';

@Injectable()
export class HelpEffects {

  loadCountries$ = createEffect(() => this._actions$.pipe(
    ofType(loadCountriesAction),
    switchMap(({loadNew}) => combineLatest([
      this._getLocation(),
      this._getCountryPhonesMapping(),
      this._getCountries(loadNew),
    ])),
    map(([location, countriesMapping, countries]) => {
      const countryCode = this._cookieService.get(COUNTRY_CODE_COOKIE) || countriesMapping[location.countryCode] || DEFAULT_COUNTRY_CODE;
      const selectedCountry = countries.find((country) => country.countryCode === countryCode) || countries[0];

      return setSelectedCountryAction({selectedCountry});
    })
    )
  );

  loadArticlesAndVideos$ = createEffect(() => this._actions$.pipe(
    ofType(loadArticlesAndVideosAction),
    switchMap(({loadNew}) => combineLatest([
      this._helpStore.select(selectAppPage),
      this._getArticles(loadNew),
      this._getVideos(loadNew),
    ])),
    switchMap(([page, articles, videos]) => [
      setFilteredArticlesAction({filteredArticles: this._filterContent(articles, page)}),
      setFilteredVideosAction({filteredVideos: page === 'login' ? [] : videos}),
    ])
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _helpService: HelpService,
    private readonly _cookieService: CookieService,
    private readonly _helpStore: Store<IHelpState>,
  ) {
  }

  private _getLocation(): Observable<ITimeZone> {
    return this._selectOrLoad<ITimeZone>(selectUserLocation, this._loadLocation.bind(this));
  }

  private _loadLocation(): Observable<ITimeZone> {
    return this._helpService.getLocation()
      .pipe(tap((userLocation) => this._helpStore.dispatch(setUserLocationAction({userLocation}))));
  }

  private _getCountryPhonesMapping(): Observable<IStringMap> {
    return this._selectOrLoad<IStringMap>(selectCountryPhonesMapping, this._loadCountryPhonesMapping.bind(this));
  }

  private _loadCountryPhonesMapping(): Observable<IStringMap> {
    return this._helpService.getCountryPhonesMapping()
      .pipe(tap((countryPhonesMapping) => this._helpStore.dispatch(setCountryPhonesMappingAction({countryPhonesMapping}))));
  }

  private _getCountries(loadNew: boolean): Observable<HelpCountries> {
    return this._selectOrLoad<HelpCountries>(selectCountries, this._loadCountries.bind(this), loadNew);
  }

  private _loadCountries(): Observable<HelpCountries> {
    return this._helpService.getHelpCountries()
      .pipe(tap((countries) => this._helpStore.dispatch(setCountriesAction({countries}))));
  }

  private _getArticles(loadNew: boolean): Observable<Articles> {
    return this._selectOrLoad<Articles>(selectArticles, this._loadArticles.bind(this), loadNew);
  }

  private _loadArticles(): Observable<Articles> {
    return this._helpService.getArticles()
      .pipe(tap((articles) => this._helpStore.dispatch(setArticlesAction({articles}))));
  }

  private _getVideos(loadNew: boolean): Observable<Videos> {
    return this._selectOrLoad<Videos>(selectVideos, this._loadVideos.bind(this), loadNew);
  }

  private _loadVideos(): Observable<Videos> {
    return this._helpService.getVideos()
      .pipe(tap((videos) => this._helpStore.dispatch(setVideosAction({videos}))));
  }

  private _filterContent(content: Articles, appPage: string = ''): Articles {
    return content && content.filter((item) => item.pages.split(/\s*[\s,]\s*/)
      .indexOf(appPage) !== -1);
  }

  private _selectOrLoad<Data>(selector: MemoizedSelector<IHelpState, Data>, loadFn: () => Observable<Data>, loadNew: boolean = false): Observable<Data> {
    return this._helpStore.select(selector)
      .pipe(
        first(),
        switchMap((data) => !data || loadNew ? loadFn() : of(data))
      );
  }
}
