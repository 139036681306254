import {CommonModule} from '@angular/common';
import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {IUnleashConfig} from '@ideals/types';
import {FeatureToggleDirective} from './feature-toggle.directive';
import {FeatureToggleService} from './feature-toggle.service';

@NgModule({
  declarations: [FeatureToggleDirective],
  exports: [FeatureToggleDirective],
  imports: [CommonModule],
})
export class FeatureToggleModule {
  static forRoot(config: IUnleashConfig): ModuleWithProviders<FeatureToggleModule> {
    return {
      ngModule: FeatureToggleModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (featureToggleService: FeatureToggleService) => {
            return () => featureToggleService.initialize(config);
          },
          deps: [FeatureToggleService],
          multi: true
        }
      ],
    };
  }
}
