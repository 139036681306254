export const INVALID_CREDENTIAL = 'INVALID_CREDENTIAL';
export const INVALID_USER_OR_CREDENTIALS = 'INVALID_USER_OR_CREDENTIALS';
export const INVALID_RETURN_URL = 'INVALID_RETURN_URL';
export const SESSION_COOKIE_DOES_NOT_EXIST = 'SESSION_COOKIE_DOES_NOT_EXIST';
export const COOKIE_NOT_DEFINED = 'COOKIE_NOT_DEFINED';
export const USER_CREATED = 'USER_CREATED';
export const USER_DELETED = 'USER_DELETED';
export const INVALID_COOKIE = 'INVALID_COOKIE';
export const PASSWORD_NOT_UNIQUE = 'PASSWORD_NOT_UNIQUE';
export const USER_CREDENTIAL_NOT_CHECKED = 'USER_CREDENTIAL_NOT_CHECKED';
export const USER_NAME_REQUIRED = 'USER_NAME_REQUIRED';
