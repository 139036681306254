export enum NavigationKeys {
  Backspace = 1,
  Delete,
  Tab,
  Escape,
  Enter,
  Home,
  End,
  ArrowLeft,
  ArrowRight,
  Clear,
  Copy,
  Paste,
}
