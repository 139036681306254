import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PageMessagesService} from '@ideals/partial-views/page-messages';
import {HTTPStatus} from '@ideals/types';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AUTO_CLOSE_TIMEOUT} from '../../types';

const openedAlerts = new Map<string, string>();

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(private readonly _pageMessages: PageMessagesService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap((event) => {
          const url = request.url;

          if (event instanceof HttpResponse && openedAlerts.has(url)) {
            const alert = this._pageMessages.messages.find((message) => message.caption === openedAlerts.get(url));

            if (alert) {
              this._pageMessages.close(alert);
              openedAlerts.delete(url);
            }
          }
        }),
        catchError((err) => {
          const {status} = err;
          let errorMessage: string;

          switch (status) {
            case HTTPStatus.NoConnection:
              errorMessage = 'common.ERROR.check_your_internet_connection';
              break;

            case HTTPStatus.InternalServerError:
            case HTTPStatus.NotImplemented:
            case HTTPStatus.BadGateway:
            case HTTPStatus.ServiceUnavailable:
            case HTTPStatus.GatewayTimeout:
              errorMessage = 'common.ERROR.your_request_cannot_be_processed';
              break;

            default:
              break;
          }

          if (errorMessage) {
            const url = request.url;
            openedAlerts.set(url, errorMessage);
            this._pageMessages.error({
              text: errorMessage,
              timeToClose: AUTO_CLOSE_TIMEOUT
            });

            setTimeout(() => {
              openedAlerts.delete(url);
            }, AUTO_CLOSE_TIMEOUT);
          }

          return throwError(err);
        })
      );
  }
}
