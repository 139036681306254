import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserService} from '@ideals/services/browser';

import {PopupComponent} from './popup.component';
import {PopupDirective} from './popup.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PopupDirective, PopupComponent],
  exports: [PopupDirective, PopupComponent],
  providers: [BrowserService]
})
export class PopupModule {
}
