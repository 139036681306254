import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {HelpEffects} from './help.effects';

import {reducer} from './help.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature('help', reducer),
    EffectsModule.forFeature([HelpEffects])
  ],
  exports: [StoreModule],
})
export class HelpStoreModule {
}
