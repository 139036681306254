import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';
import {InformationStatus} from '@ideals/types';
import {IAlert} from '../models';
import {PageMessagesService} from '../page-messages.service';

const ICON_CLASSES = {
  [InformationStatus.Warning]: 'icons-warning',
  [InformationStatus.Information]: 'icons-info',
  [InformationStatus.Success]: 'icons-tick-round'
};

@Component({
  selector: 'ideals-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent {
  @Input()
  public alert: IAlert;

  @HostBinding('class.host')
  public get host(): boolean {
    return true;
  }

  @HostBinding(`class.${InformationStatus.Danger}`)
  public get danger(): boolean {
    return this.alert.type === InformationStatus.Danger;
  }

  @HostBinding(`class.${InformationStatus.Success}`)
  public get success(): boolean {
    return this.alert.type === InformationStatus.Success;
  }

  @HostBinding(`class.${InformationStatus.Warning}`)
  public get warning(): boolean {
    return this.alert.type === InformationStatus.Warning;
  }

  @HostBinding(`class.${InformationStatus.Information}`)
  public get information(): boolean {
    return this.alert.type === InformationStatus.Information;
  }

  @HostBinding('class.hidden')
  public get hidden(): boolean {
    return !this.alert.isOpened;
  }

  @HostBinding('class.show')
  public get showed(): boolean {
    return this.alert.isOpened;
  }

  @HostBinding('class')
  public get customClass(): string {
    return this.alert.customClass || '';
  }

  constructor(private readonly _pageMessages: PageMessagesService) {
  }

  get iconClass(): string {
    return ICON_CLASSES[this.alert.type] || '';
  }

  public close(): void {
    this._pageMessages.close(this.alert);
  }
}
