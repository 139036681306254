/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import {IIntercomSettings} from './intercom-settings.interface';

export const SESSION_DURATION_INTERCOM = 500;
export const MAIN_COLOR = '#2C9C74';
export const VERTICAL_PADDING_AUTHENTICATED = 0;
export const VERTICAL_PADDING_NON_AUTHENTICATED = 50;

export class IntercomSettings {
  public readonly app_id: string;
  public readonly email: string;
  public readonly user_hash: string;
  public readonly session_duration: number;
  public readonly IP: string;
  public readonly background_color: string;
  public readonly action_color: string;
  public readonly vertical_padding: number;
  public readonly enabled: boolean;
  public readonly 'First name': string;
  public readonly 'Last name': string;
  public readonly 'Is authenticated': boolean;
  public readonly 'Auth type': string;

  constructor({
                app_id,
                ip,
                enabled,
                auth_type,
                is_authenticated,
                email,
                user_hash,
                first_name,
                last_name,
                session_duration,
                background_color,
                action_color,
              }: IIntercomSettings) {
    this.app_id = app_id;
    this.IP = ip;
    this.background_color = background_color || MAIN_COLOR;
    this.action_color = action_color || MAIN_COLOR;
    this.enabled = enabled;
    this['Auth type'] = auth_type;
    this['Is authenticated'] = is_authenticated;
    this.vertical_padding = is_authenticated ? VERTICAL_PADDING_AUTHENTICATED : VERTICAL_PADDING_NON_AUTHENTICATED;

    if (is_authenticated) {
      this.email = email;
      this.user_hash = user_hash;
      this.session_duration = session_duration || SESSION_DURATION_INTERCOM;
      this['First name'] = first_name;
      this['Last name'] = last_name;
    }
  }
}
