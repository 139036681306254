import {
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges
} from '@angular/core';
import {BrowserService} from '@ideals/services/browser';
import {BODY, PLACEMENT, VIEWPORT, WINDOW} from '@ideals/types';
import Tooltip, {Options} from 'tooltip.js';

@Directive({
  selector: '[ideals-tooltip], [idealsTooltip]',
  exportAs: 'idealsTooltip',
})
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input('ideals-tooltip')
  public content: string;

  @Input('showDelay')
  public showDelay = 500;

  @Input('hideDelay')
  public hideDelay = 0;

  @Input('placement')
  public placement = PLACEMENT.Bottom;

  @Input('placementBehaviour')
  public placementBehaviour = [PLACEMENT.Bottom, PLACEMENT.Right, PLACEMENT.Left];

  @Input('tooltipHTML')
  public html = false;

  @Input('disabled')
  public disable = false;

  @Input('appendToElement')
  public set appendToElement(value: boolean) {
    this._appendToElement = value;
  }

  public get appendToElement(): boolean {
    return this._appendToElement;
  }

  @Input('tooltipWidthAuto')
  public set tooltipWidthAuto(value: boolean) {
    if (value) {
      this._appendToElement = value;
    }
    this._tooltipWidthAuto = value;
  }

  public get tooltipWidthAuto(): boolean {
    return this._tooltipWidthAuto;
  }

  @HostBinding('class.ideals-tooltip__width-auto')
  get isWidthAuto(): boolean {
    return this._tooltipWidthAuto;
  }

  @HostBinding('class.ideals-tooltip')
  public readonly directiveClass = true;

  public tooltipInstance: Tooltip;

  private _container = BODY;
  private _boundariesElement = VIEWPORT;
  private _trigger = 'manual';
  private _eventScrollOptions = {capture: true, passive: true};
  private _appendToElement: boolean;
  private _tooltipWidthAuto: boolean;
  private _scheduledShowTimeout: any;
  private _scheduledHideTimeout: any;
  private _hideTooltip = (): void => {
    this._removeScrollListener();
    clearTimeout(this._scheduledShowTimeout);
    this.tooltipInstance.dispose();
  }

  constructor(
    private _elementRef: ElementRef,
    private _zone: NgZone,
    private readonly _browserService: BrowserService,
    @Inject(WINDOW) private readonly _window: Window
  ) {
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (!this.disable) {
      this._addScrollListener();
      clearTimeout(this._scheduledHideTimeout);
      this._scheduledShowTimeout = setTimeout(() => this.tooltipInstance.show(), this.showDelay);
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (!this.disable) {
      this._removeScrollListener();
      clearTimeout(this._scheduledShowTimeout);
      this._scheduledHideTimeout = setTimeout(() => this.tooltipInstance.hide(), this.hideDelay);
    }
  }

  public get element(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  public ngOnInit(): void {
    this._createTooltipInstance();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.tooltipInstance) {
      if (this.tooltipInstance._isOpen && this._isContentChanged(changes)) {
        this.tooltipInstance.updateTitleContent(changes.content.currentValue);
      } else {
        this.tooltipInstance.dispose();
        this._createTooltipInstance();
      }
    }
  }

  public ngOnDestroy(): void {
    clearTimeout(this._scheduledHideTimeout);
    clearTimeout(this._scheduledShowTimeout);
    this.tooltipInstance.dispose();
  }

  private _createTooltipInstance(): void {
    const container = this._appendToElement ? this._elementRef.nativeElement : this._container;

    this._zone.runOutsideAngular(() => {
      const tooltipOptions: Options = {
        container,
        delay: this.showDelay,
        title: this.content,
        placement: this.placement,
        boundariesElement: this._boundariesElement,
        trigger: this._trigger,
        html: this.html,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            flip: {
              behavior: this.placementBehaviour
            }
          }
        }
      } as Options;

      this.tooltipInstance = new Tooltip(this._elementRef.nativeElement, tooltipOptions);
    });
  }

  private _addScrollListener(): void {
    this._window.addEventListener('scroll', this._hideTooltip, this._eventScrollOptions);
  }

  private _removeScrollListener(): void {
    this._window.removeEventListener('scroll', this._hideTooltip, this._eventScrollOptions);
  }

  private _isContentChanged(changes: SimpleChanges): boolean {
    const {previousValue = '', currentValue = ''} = changes.content || {};
    const contentChanged = previousValue && (previousValue !== currentValue);

    return contentChanged;
  }
}

@Directive({
  selector: '[idealsOverflow]',
})
export class TooltipOverflowDirective {
  @Input()
  public skipOverflowCheck = false;

  @HostBinding('class.ideals-overflow')
  public readonly directiveClass = true;

  constructor(
    @Self()
    @Optional()
    @Inject(forwardRef(() => TooltipDirective))
    private _tooltip: TooltipDirective,
  ) {
  }

  @HostListener('mouseover')
  private _checkOverflow(): void {
    const htmlElement: HTMLElement = this._tooltip.element;
    const isTooltipDisabled = htmlElement.className.includes('overflow-disabled');
    this._tooltip.disable = isTooltipDisabled
      || this.skipOverflowCheck
      ? isTooltipDisabled
      : htmlElement.scrollWidth === htmlElement.clientWidth;
  }
}
